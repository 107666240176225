
import { Emotions } from "@/store/purProcess";
import { reactive, onMounted, ref, createVNode } from "vue";
import { timeago, DateChinaLong, OutTime } from '@/store/formatTime';
import { TimeChina } from "@/store/guid";
import { Modal, message, notification } from "ant-design-vue";
import * as signalR from "@microsoft/signalr";
import Emotion from "@/components/input/EmotionIndex.vue";
import router from "@/router";
import store from "@/store";
import request from "@/api/http";
import { ScanOutlined, BarsOutlined,UserSwitchOutlined, DisconnectOutlined, ShakeOutlined, DeleteOutlined, HomeOutlined, SmileOutlined, PictureOutlined, SendOutlined, FullscreenExitOutlined, FullscreenOutlined, MailOutlined, IssuesCloseOutlined, CloseCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined, EditOutlined, PaperClipOutlined, ExclamationCircleOutlined, CustomerServiceOutlined, CommentOutlined } from '@ant-design/icons-vue';//issues-close-outlined /><close-circle-outlined
import { any } from "vue-types";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
interface response {
  id: string; boxColor: string; createTime: string; sendMsg: string; userName: string; HeadPortrait: string; quote: string; ip: string; ipCity: string; userEmail: string
}
interface roomList {
  id: string;ip: string;ipCity: string; name: string; msg: string; sendmsg: string; news: boolean; response1: response[]; response2: response}
interface JoinGroupDto {
  groupId: string;
  email: string;
  token: string;
  ip: string;
  ipCity: string;
  classs: string;
}
interface SendGroupMsgDto {
  groupId: string;
  msg: string;
  quote: string;
  userId: string;
  userName: string;
  userEmail: string;
  HeadPortrait: string;
  classs: string;
  createTime: string;
  ip: string;
  ipCity: string;
}
let connection: any;
//图片上传
interface Result {
  dataURL: string;
  blobURL: string;
}
export default {
  name: "QCR",
  components: {
    VuePictureCropper, DisconnectOutlined, UserSwitchOutlined,FullscreenExitOutlined, ShakeOutlined, FullscreenOutlined, SendOutlined, ScanOutlined, BarsOutlined, DeleteOutlined, HomeOutlined,
    MailOutlined, SmileOutlined, PictureOutlined, IssuesCloseOutlined, CloseCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined, EditOutlined, ExclamationCircleOutlined, PaperClipOutlined, CustomerServiceOutlined, CommentOutlined,
    Emotion,
  },
  setup() {
    let userInfo: any = null;
    let tempStore = store;
    if (tempStore.state.userInfo) {
      userInfo = JSON.parse(tempStore.state.userInfo!);
    }

    //引用打开
    const handleQuote = (sendMsg: string) => {
      state.quote = sendMsg;
    }
    //清理引用数据
    const handleCloseQuoTe = () => {
      state.quote = "";
    }

    //撤回消息
    const handleRecall = (responseNumber: number, sendMsg: string, createTime: string) => {
      Modal.confirm({
        title: '撤回消息(1分钟内有效)',
        icon: createVNode(ExclamationCircleOutlined),
        content: '撤回' + timeago(createTime) + '发布的【' + sendMsg + '】，撤回时，各客户端同时撤回',
        okText: '提交',
        cancelText: '取消',
        onOk() {
          SendMsg(state.groupId, sendMsg, "Recall", createTime, "");
        },
        onCancel() { },
      });
    }
    //修改（加密）数据
    const handleEdit = (index: number, sendMsg: string) => {
      // state.edit[index]=true;
      state.editMsg = sendMsg;
      // //console.log(sendMsg.toString());
    }
    //修改（加密）数据
    const handleEditSave = (index: number, sendMsg: string, createTime: string, sendMsgOld: string) => {
      SendMsg(state.groupId, sendMsg, "Edit", createTime, sendMsgOld);
    }


    const roomList = ref<roomList[]>([]);
    const listClass = ref<boolean>(false);
    let inputroom = ref<string>("0");
    let tcmsg = ref<string>();
    let articleData = ref<any>();
    let articleNowMsgs = ref<any>();//handleWindowClear,handleGoHome,
      const state = reactive({
      msg: "",
      userId: "",
      userName: "",
      quote: "",
      groupId: "",
      groupName: "",
      teaList: [] as string[],
      ip: "",
      ipCity: "",
      // edit:[] as Boolean[],state.dictTea
      editMsg: "",
      sendMailQ: [] as string[],
      sendMailAccount: [] as string[],
      sendMailA: "",
      sendMailOpen: false,
      sendAiOpen: false,
      sendMailRadioQ: 0,
      isPicShowDialog: false,
      pic: "",
      id: "qus",
      openQR: false,
      location: window.location.href,
      teaDelayComeTime: 0,
      teaReturnTime: 20,
      teaComeTrue: false,
      teacountDownContactTea: 60,
      teaContactClass: "检查",
      dictUser:false,
      dictTea:"",
    });
    const handleWindowClear = () => {
      message.success("缓存清理完成，并退出账户");
      window.localStorage.clear();
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
    const handleGoHome = () => {
      router.replace("/PurHome");
    }
    const ListClassClick = () => {
      listClass.value = listClass.value == true ? false : true;
    }
    const handleChange = (id: string) => {
      let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];//获取高度
      var number = roomList.value.findIndex(roomList => roomList.id === state.groupId)//查找点击前的ID位置
      roomList.value[number].sendmsg = state.msg;//把输入中的内容存入sendmsg
      articleData.value = null;//清理列表历史聊天记录
      let item = roomList.value.find(i => i.id === id);//查找点击后的ID数据
      articleData.value = roomList.value[number];//写入数据中
      roomList.value[number].news = false;
      state.groupName = roomList.value[number].name;
      state.msg = item?.sendmsg!;//把要发送的资料放到输入框中
      state.quote = "";
      state.dictUser =false;
      state.groupId = id;//更新所在ID列表位置
      if (item?.name != null) {
        state.groupName = item?.name;
      }
      setTimeout(function () {
        //设置滚动条到最底部
        chatScrollTop.scrollTop = chatScrollTop.scrollHeight;
      }, 200);
    };
    let chatQuestionsList = ref<any>(null);
    let guestId: any = JSON.parse(window.localStorage.getItem('GuestId')!);



    
    //获取聊天室历史数据
    function setLocalStorage(id: string, data: any) {
      window.localStorage.setItem(id, JSON.stringify(data));//chatQuestionsList.value
    };
    //关闭room
    const handleClose = (id: string) => {
      roomList.value = roomList.value.filter(roomList => roomList.id !== id);
      // window.localStorage.removeItem('ChatRoom'+id);
      window.localStorage.setItem('Chat'+state.groupId, JSON.stringify(roomList.value));//chatQuestionsList.value
    };



    const isShow = ref(false);
    const onShow = () => {
      isShow.value = true;
    };
    let data = reactive({
      content: "",
      isShowEmotion: false,
    });

    const handlePublish = () => {
      data.isShowEmotion = false;
      if (state.msg.length > 0) {
        SendMsg(state.groupId, state.msg, "Send", "", state.quote);
      }
      else {
        message.warning("请输入内容");
      }
    }
    let urlId = router.currentRoute.value.params.id.toString();
    
    const isShows = ref("");
    //图片上传ON
    const uploadInput = ref<HTMLInputElement | null>(null);
    const pic = ref<string>("");
    const result: Result = reactive({
      dataURL: "",
      blobURL: "",
    });
    const isShowDialog = ref<boolean>(false);
    /**
     * 选择图片
     */
    const selectFile = (e: Event): void => {
      // 重置上一次的结果
      result.dataURL = "";
      result.blobURL = "";
      // 如果有多个裁剪框，也需要重置掉裁剪目标的值，避免使用同一张图片无法触发watch
      pic.value = "";
      // 获取选取的文件
      const target = e.target as HTMLInputElement;
      const { files } = target;
      if (!files) return;
      const file: File = files[0];
      // 转换为base64传给裁切组件
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        // 更新裁切弹窗的图片源
        pic.value = String(reader.result);
        // 显示裁切弹窗
        isShowDialog.value = true;
        // 清空已选择的文件
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };
    /**
     * 获取裁切结果
     */
    const getResult = async (): Promise<void> => {
      // //console.log(cropper)
      // 获取生成的base64图片地址
      const base64: string = cropper.getDataURL();
      // // 获取生成的blob文件信息
      // const blob: Blob = await cropper.getBlob();
      // // 获取生成的file文件信息
      // const file = await cropper.getFile({
      //   fileName: "测试文件名，可不传",
      // });
      //console.log({ base64, blob, file });
      // 把base64赋给结果展示区
      result.dataURL = base64;
      try {
        uploaderHeadPortrait()
      } catch (e) {
        result.blobURL = "";
      }
      // 隐藏裁切弹窗
      isShowDialog.value = false;
    };
    /**
     * 清除裁切框
     */
    const clear = (): void => {
      cropper.clear();
    };
    /**
     * 重置默认的裁切区域
     */
    const reset = (): void => {
      cropper.crop();
      cropper.reset();
    };
    const uploaderHeadPortrait = () => {
      if (result.dataURL.length < 10) {
        //检查是否有图片数据
        message.error("图片选择有误！");
      } else {
        request({
          url: "/ChatApi/SendPicMsg",
          method: "post",
          data: {
            groupId: state.groupId,
            userId: state.userId,
            msg: result.dataURL,
            ip: state.ip,
            ipCity: state.ipCity,
          },
        }).then((res: any) => {
          if (!res.data.success) {
            Modal.error({
              title: "提示",
              content: res.data.msg,
            });
          } else {
            message.success(res.data.msg);
          }
        });
      }
    };
    //图片上传END
    const ListCallTEA = () => {
      const joinGroupDto = reactive<JoinGroupDto>({
        token: "给你发来震动~",
        groupId: state.groupId,
        email: state.userName,
        ip: state.ip,
        ipCity: state.ipCity,
        classs: "qus",
      });
      ChatTeaState(joinGroupDto);
    };

    const countDownTEACome = () => {
      if (state.teaDelayComeTime > 0) {
        state.teaDelayComeTime--;
        setTimeout(countDownTEACome, 1000);
      }
      else {
        if (state.teaComeTrue != true) {
          SendTeaMail(state.groupId, state.userName, state.ip, state.ipCity);
        }
        state.teaDelayComeTime = 0;
      }
    }
    function SendTeaMail(groupId: string, userName: string, ip: string, ipCity: string) {
      request({
        url: "/ChatApi/CallTeaEmail",
        method: "post",
        data:  {
          groupId: groupId,
          userName: userName,
          ip: ip,
          ipCity: ipCity,
        },
      }).then((res: any) => {
        if (res.data.success) {
          message.success(TimeChina() + res.data.msg, 5);
        } else {
          message.error(res.data.msg);
        }
      });
    }
    //检查老师在线情况
    function countDown() {
      //console.log(state.teaDelayComeTime)
      if (state.teaDelayComeTime > 1) {
        state.teaDelayComeTime--;
      }
      else {
        const joinGroupDto = reactive<JoinGroupDto>({
          token:tempStore.state.token!,
          groupId: state.groupId,
          email: "",
          ip: state.ip,
          ipCity: state.ipCity,
          classs: "qus",
        });
        state.teaDelayComeTime = 260 ;//20秒检测一次服务师是否在线处理你的事情
        setTimeout(function () {
          ChatTeaState(joinGroupDto)
        }, 3000)
      }
      setTimeout(countDown, 1000);

    };
    function countDownContactTea() {
      if (state.teacountDownContactTea > 1) {
        state.teacountDownContactTea--;
        if (state.teaContactClass == "离线") {
          state.dictTea = "(服务师赶来中..请稍候" + state.teacountDownContactTea + "s)";
          setTimeout(countDownContactTea, 1000);
        }
        state.teacountDownContactTea == 0
      }
    }
    function ChatTeaState(joinGroupDto: JoinGroupDto) {
      connection
        .invoke("TeaState", joinGroupDto)
        .catch(function (err: any) {
          message.warning("服务师匹配异常~请检查网络..")
        });
    }


    function JoinChat(joinGroupDto: JoinGroupDto) {
      // //console.log(1);
      connection
        .invoke("JoinChat", joinGroupDto)
        .catch(function (err: any) {
          message.warning("聊天室已超过使用时限。正为你准备跳转到首页..")
        });
    }
    function SendMsg(id: string, msg: string, classs: string, createTime: string, quote: string) {
      const sendGroupMsgDto = reactive<SendGroupMsgDto>({
        groupId: urlId,
        msg: msg,
        quote: quote,
        userId: userInfo.id,
        userEmail:tempStore.state.token!,
        userName:userInfo.UserName,
        classs: classs,
        createTime: createTime,
        ip: state.ip,
        ipCity: state.ipCity,
        HeadPortrait: userInfo.HeadPortrait,
      });
      connection
        .invoke("SendMsg", sendGroupMsgDto)
        .catch(function (err: any) {
        });
    }
    // const OutTime=(urlId:string)=>{
    //   var time=urlId.substring(12,2)+"-"+urlId.substring(14,2)+"-"+urlId.substring(16,2)+" "+"23:59:59";
    //   return time
    // }
    const openNotification = (message: string, description: string, id: string, duration: number, classs: string) => {
      notification.open({
        message: message,
        description: description,
        style: {
          width: '250px',
          marginLeft: `${5 - 10}px`,
        },
        class: 'notification-custom-class',
        duration: duration,//延时关闭
        onClick: () => {
          handleChange(id);
        },
      });
    };
    onMounted(async function () {

      connection = new signalR.HubConnectionBuilder()
        .withUrl(store.state.api + "/chatHub")//https://www.aowok.com
        .withAutomaticReconnect()
        .build();
      await connection.start(
        
        setTimeout(function () {
          state.groupId=urlId;
          const joinGroupDto = reactive<JoinGroupDto>({
            token:tempStore.state.token!,
            groupId: urlId,
            email: "",
            ip: guestId.comment,
            ipCity: "",
            classs: "qus",
          });
          JoinChat(joinGroupDto);

        }, 1000)
      );

      connection.on("ReceiveGroup", (sendGroupMsgDto: SendGroupMsgDto) => {
        //console.log(sendGroupMsgDto);
        if (sendGroupMsgDto.userId != state.groupId) {
          state.teaContactClass = "在线";
          state.teaReturnTime = 5;
          state.dictTea = "(您有新的消息)";
        }
        if (sendGroupMsgDto.classs == "Send") {
          //console.log(sendGroupMsgDto)
          var number = roomList.value.findIndex(roomList => roomList.id === state.groupId)//查找点击前的ID位置
          roomList.value[number].response1.push({
            id:sendGroupMsgDto.groupId,
            boxColor: sendGroupMsgDto.userId == sendGroupMsgDto.groupId||sendGroupMsgDto.userId==userInfo.id ? 'mebox' : 'otherbox',
            createTime: sendGroupMsgDto.createTime,
            sendMsg: sendGroupMsgDto.msg,
            userName: sendGroupMsgDto.userName,
            userEmail: sendGroupMsgDto.userEmail,
            HeadPortrait: sendGroupMsgDto.HeadPortrait==null?"":sendGroupMsgDto.HeadPortrait,
            quote: sendGroupMsgDto.quote,
            ip: sendGroupMsgDto.ip,
            ipCity: sendGroupMsgDto.ipCity,
          });

          // //console.log("2"+groupId+name+a[0]+a[1])
          // var number=roomList.value.findIndex(roomList => roomList.id===state.groupId)//查找点击前的ID位置
          roomList.value[number].sendmsg = "";//清除準備輸入的内容
          roomList.value[number].news = sendGroupMsgDto.groupId != state.groupId ? true : false;//清除準備輸入的内容groupId!=state.groupId?true:false
          setTimeout(function () {
            let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];
            if (chatScrollTop.scrollHeight > chatScrollTop.clientHeight) {
              //设置滚动条到最底部
              chatScrollTop.scrollTop = chatScrollTop.scrollHeight;
              // chatScrollTop.setAttribute("style", "color: red");
            }
          }, 300)
          // //console.log(state.groupId.toString());
          // //console.log(groupId);
          if (state.groupId == sendGroupMsgDto.groupId || sendGroupMsgDto.groupId == state.groupId) {
            state.msg = "";
            state.quote = "";
          }
          else {
            openNotification(name + ":", sendGroupMsgDto.msg, sendGroupMsgDto.groupId, 5, "");
          }
        }
        else if (sendGroupMsgDto.classs == "Recall" || sendGroupMsgDto.classs == "Delete") {

          var number = roomList.value.findIndex(roomList => roomList.id === sendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.sendMsg === sendGroupMsgDto.msg)//查找点击前的ID位置
          roomList.value[number].response1[responseNumber].boxColor = "nullbox";
          roomList.value[number].response1[responseNumber].sendMsg = sendGroupMsgDto.classs == "Recall" ? "---撤回了一条消息---" : "---该信息已被删除---";
          if (sendGroupMsgDto.groupId == state.groupId) {
            // message.success(msg+classs+groupId);
            articleData.value[responseNumber].boxColor = "nullbox";
            articleData.value[responseNumber].sendMsg = sendGroupMsgDto.classs == "Recall" ? "---撤回了一条消息---" : "---该信息已被删除---";
          }
          if (sendGroupMsgDto.groupId != state.groupId) {
            openNotification(name + ":", '---撤回了一条消息---', sendGroupMsgDto.groupId, 5, "");
          }
        }
        else if (sendGroupMsgDto.classs == "Edit") {
          var number = roomList.value.findIndex(roomList => roomList.id === sendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.sendMsg === sendGroupMsgDto.quote)//查找点击前的ID位置
          roomList.value[number].response1[responseNumber].sendMsg = sendGroupMsgDto.msg;
          roomList.value[number].response1[responseNumber].quote = articleData.value[responseNumber].quote + "(平台对该信息进行加密处理)";
          message.success(roomList.value[number].response1[responseNumber].sendMsg)
          if (sendGroupMsgDto.groupId == state.groupId) {
            articleData.value[responseNumber].sendMsg = sendGroupMsgDto.msg;
            articleData.value[responseNumber].quote = articleData.value[responseNumber].quote + "(平台对该信息进行加密处理)";
            message.success(articleData.value[responseNumber].sendMsg)
          }
        }

        else if (sendGroupMsgDto.classs == "GroupName") {
          var number = roomList.value.findIndex(roomList => roomList.id === sendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.sendMsg === sendGroupMsgDto.msg)//查找点击前的ID位置
          roomList.value[number].name = sendGroupMsgDto.msg;
        }
      });
      connection.on("ReadMsgData", (roomListNow:roomList) => {
  //console.log(roomListNow)
  var number=roomList.value.findIndex(roomList => roomList.id==roomListNow.id)//查找点击前的ID位置

  //console.log(number+"number")
  if(number==-1)
    {
      countDown();
      roomListNow.news=true;
      var a=roomListNow.response1.length;
      for(var i=0 ;i<a;i++)
      {
      roomListNow.response1[i].boxColor=userInfo.email==roomListNow.response1[i].userEmail?"mebox" : "otherbox";
    }
    roomListNow.name="缘梦聊天室";
      roomList.value.push(roomListNow);
      if(roomList.value[0].response1.length<5)
      {
        roomList.value[0].response1.push({
        id: "",
          boxColor: "nullbox",
          createTime: "",
          sendMsg:"<div style='text-align:left;margin:-10px -38px;background-color:#efefef;padding:10px;border-radius:8px;'><p>请注意：</p><p style='margin: 0;'>1.本群属于互助性指导对话，需要您注意保护个人隐私。</p><p style='margin: 0;'>2.发送的图片为临时数据，刷新后将会消失。服务师离线时，将无法查看图片。<br>3.希望在本次有限的咨询时间里面，能给您最大的帮助。并且能通过和您聊天，对特定案例的解读，在分享后，能给与有同类求助的朋友，有更多的思维上的启发。</p></div>",
          userName: "",
          userEmail: "",
          HeadPortrait: "",
          quote: "",
          ip: "",
          ipCity: "",
        });
      }
      state.groupId=roomList.value[0].id;
   handleChange(roomList.value[0].id)
}

});
      connection.on("RecChatTeaState", (joinGroupDto: JoinGroupDto) => {
        var number = roomList.value.findIndex(roomList => roomList.id === state.groupId)//查找点击前的ID位置
        if (joinGroupDto.token == "在线") {
          state.teaContactClass = "在线";
          state.teaReturnTime = 5;
          state.dictUser = false;
          state.dictTea = "(服务师在线)";
          // roomList.value[number].name = "咨询室(服务师在线)";
          // state.groupName = "咨询室(服务师在线)";
          if (roomList.value[0].response1.length==0) {
              roomList.value[0].response1.push({
                id:"",
                boxColor: 'otherbox',
                createTime: new Date().toString(),
                sendMsg: "您好，我是助理小梦。请问有什么可以帮到您？",
                userName: "",
                userEmail: "",
                HeadPortrait: "",
                quote: "",
                ip: "",
                ipCity: "",
              });
            }
        }
        else if (joinGroupDto.token == "离线") {
          state.teaContactClass = "检查";
          state.dictTea = "(服务师离线)";
          // roomList.value[number].name = "咨询室(服务师离线)";
          // state.groupName = "咨询室(服务师离线)";
          state.dictUser = false;
          if (state.teaReturnTime == 20 || state.teacountDownContactTea > 1) {
            state.teacountDownContactTea = 60;
            countDownContactTea();
            if (state.teaReturnTime == 20) {
              roomList.value[0].response1.push({
                id:"",
                boxColor: 'otherbox',
                createTime: new Date().toString(),
                sendMsg: "您好，我是助理小梦。由于服务师不在线，正为您联络服务师，您可以预留言[5条]信息。服务师浏览后,将尽快给予回复。如等待时间≥5分钟，建议您在工作日的（10:00-18:00)，返回本咨询室，服务师阅读您的留言后，将给您提供解答[握手]。",
                userName: "",
                userEmail: "",
                HeadPortrait: "",
                quote: "",
                ip: "",
                ipCity: "",
              });
            }
            state.teaReturnTime = state.teaReturnTime + 10;
          }
          else {
            state.teaReturnTime = state.teaReturnTime + 10;
            state.dictTea = "(服务师离线)";
            // roomList.value[number].name = "咨询室(服务师离线)";
            // state.groupName = "咨询室(服务师离线)";
            // //console.log("服务师离线"+state.teaReturnTime);
          }


        }
        else if (joinGroupDto.token == "输入中") {
          state.teaReturnTime = 30;
          state.dictTea = "(对方输入中...)";
          state.dictUser = true;
          // state.groupName = "咨询室(对方输入中...)";
        }
      });




    });
    return {
      VuePictureCropper, uploaderHeadPortrait, reset, clear, getResult, selectFile, isShowDialog, result, pic, handleWindowClear, handleGoHome,
      handleCloseQuoTe,
      handleQuote,
      handleRecall,
      ListClassClick,
      listClass,
      articleNowMsgs,
      articleData,
      tcmsg,
      userInfo,
      store,
      handleChange,
      handleClose,
      roomList,
      inputroom,
      timeago,
      state,
      SendMsg,
      handleEdit,
      handleEditSave,
      // SendUserMessage,
      // SendGroupMessage,
      handlePublish,
      isShows,
      connection,
      TimeChina,
      // chatRecordList,
      chatQuestionsList,
      data,
      onShow,
      isShow,
      DateChinaLong,
      guestId,
      Emotions,
      ListCallTEA,
    };
  },
};
