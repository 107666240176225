
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message,Progress,RadioButton,RadioGroup,MenuItem,Menu,Dropdown } from "ant-design-vue";
import { DateMinute,SecondToTime } from "@/store/formatTime";
import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";
import { TopicClass2,TopicClass3 } from "@/store/watermark";
import { LevelLine} from "@/store/purProcess";
import iGuidelines from "@/components/system/Guidelines.vue";
import psyMemo from "@/components/Psychotheraphy/PsyMemo.vue";
import { SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined ,RollbackOutlined,LoadingOutlined} from '@ant-design/icons-vue';
import { number } from "echarts/core";
interface QusAns {
  qus: string;
ans: string;
ans2: string;
}
interface TopicsDto {
  id: string;
  orders: string;
  mark1: string;
  qus: string;
  levels: number;
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  fenTrue:number;
  fenFalse:number;
  ans:string;
  parses:string;
  class1:string;
  class2:string;
  class3:string;
}
interface TopicsCount {
  all:number;
  ture:number;

}
export default defineComponent({
  name: "Psychotheraphy",
  components: {
    Vcode,
    "v-psyMemo":psyMemo,
        "a-progress": Progress,
    "a-radio-button": RadioButton,
    "a-radio-group": RadioGroup,
      "a-menu":Menu,
       "a-menu-item":MenuItem,
       "a-dropdown":Dropdown,
    "iGuidelines":iGuidelines,
    SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined,RollbackOutlined,LoadingOutlined
  },
  setup() {
const topicSendDto = reactive({class1:"心理治疗师",class2:"全部",class3:[] as string[],class4:"练错题",class5:"打乱选项",ans:"",id:"",pagekey:0});
const topicsDto = ref<TopicsDto>();
let qusAns = ref<QusAns[]>([]);
const topicsNewsDto3 = ref<TopicsDto>();
const topicsNewsDto2 = ref<any>();
  const state = reactive({
      fenTrue: 0,
      fenFalse: 0,
      countLong: 0,
      ans:"",
      nextButton: false,
      count:"",
      data:"",
    });
const handleClass3= () => {
      topicSendDto.class3=[];
}

const buttonOnS=() =>{
  qusAns.value.push({
      qus: topicsDto.value?.qus==undefined?"":topicsDto.value?.qus,
      ans: topicsDto.value?.ans==undefined?"":topicsDto.value?.ans,
      ans2:state.ans,
  });
if(state.ans==topicsDto.value?.ans)
{
  state.fenTrue++;
  topicSendDto.ans="对";
  state.nextButton=true;
  setTimeout(function () {
  state.nextButton=false;
  },1000)
}
else{
  state.fenFalse++;
  topicSendDto.ans="错";
}
}
const buttonNext=() =>{
  state.nextButton=false;
  if(topicsDto.value!=null){
topicSendDto.id=topicsDto.value.id;
  }
onS();
}
const buttonCount=() =>{
  request({
        url: "/MentonApi/PsychotheraphycCount",
        method: "get",
      }).then((res: any) => {
        if (res.data.success) {
          state.count=res.data.response;
        }
      });
}
const buttonUpData=() =>{
  request({
        url: "/MentonApi/PsyUpData",
        method: "post",
        data:
        {
            Data: state.data
        },
      }).then((res: any) => {
        if (res.data.success) {
          message.success("成功!返回数据：["+res.data.response+"]");
          state.data="";
        }

      });
}
    const onS=() =>{
      //console.log(topicSendDto)
      request({
        url: "/MentonApi/Psychotheraphy",
        method: "post",
        data: topicSendDto
      }).then((res: any) => {
        if (res.data.success) {
          topicsDto.value=res.data.response;
          //console.log(topicsDto.value)
          topicSendDto.id=res.data.response.id;
          state.ans="";
          topicSendDto.ans="";
        }
        else{
          message.error("获取信息失败");
        }
      });
    }
onS();
    return {
      DateMinute,qusAns,
      buttonCount,
      labelCol: { style: { width: "80px" } },
      wrapperCol: { span: 14 },state,onS,TopicClass2,TopicClass3,handleClass3,buttonUpData,
      SecondToTime,buttonOnS,buttonNext,topicSendDto,topicsNewsDto2,topicsNewsDto3,topicsDto,LevelLine
    };
  },
});
