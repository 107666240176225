import {  ref } from "vue";

export const getmark = () => {
  const setWatermark = (str: any) => {
    // const id = "1.23452384164.123412416";
    const id = "23421341.12312.5123.132";
    if (document.getElementById(id) !== null) {
      document.body.removeChild(document.getElementById(id)!);
    }

    //创建一个画布
    const can = document.createElement("canvas");
    //设置画布的长宽
    can.width = 250;
    can.height = 300;

    const cans = can.getContext("2d")!;
    //旋转角度
    cans.rotate((-15 * Math.PI) / 180);
    cans.font = "18px Vedana";
    //设置填充绘画的颜色、渐变或者模式
    cans.fillStyle = "rgba(200, 200, 200, 0.40)";
    //设置文本内容的当前对齐方式
    cans.textAlign = "left";
    //设置在绘制文本时使用的当前文本基线
    // cans.textBaseline = "Middle";
    //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
    cans.fillText(str, can.width / 50, can.height /2);

    const div = document.createElement("div");
    div.id = id;
    div.style.pointerEvents = "none";
    div.style.top = "0px";
    div.style.left = "0px";
    div.style.position = "fixed";
    div.style.zIndex = "-1";
    div.style.width = document.documentElement.clientWidth +"px";
    div.style.height = document.documentElement.clientHeight + "px";
    div.style.background =
      "url(" + can.toDataURL("image/png") + ") left top repeat";
    document.body.appendChild(div);
    return id;
  };

  // 该方法只允许调用一次
  const watermark = (str: string) => {
    let id = setWatermark(str);
    setInterval(() => {
      if (document.getElementById(id) === null) {
        id = setWatermark(str);
      }
    }, 300);
    window.onresize = () => {
      setWatermark(str);
    };
  };
  return { watermark };
};
export const TopicClass2 = (input:string) => {
  // var tmpcity = ref([])
var tmpcity =[] as string[];
 var sf=new Array();
sf[0]=new Array("心理治疗师","全部|医学伦理学|基础知识|相关专业知识|专业知识|专业实践能力");
sf[1]=new Array("心理治疗师多选","全部|医学伦理学|基础知识|相关专业知识|专业知识|专业实践能力");
   var i,j=new Array();
   for(i=0;i<sf.length;i++){
     if(input==sf[i][0].toString()){
       tmpcity=sf[i][1].split("|");
     }
   }
 return tmpcity
}
export const TopicClass3 = (input:string) => {
  // var tmpcity = ref([])
var tmpcity =[] as string[];
 var sf=new Array();
sf[0]=new Array("基础知识","第1章 心理学基础知识|第2章 人类心理发展|第3章 人格理论|第4章 社会心理学理论|第5章 文化与心理卫生");
sf[1]=new Array("相关专业知识","第6章 精神病学基础|第7章 心理测验与评估|第8章 健康心理学与心身医学基础|第9章 临床心理学研究方法");
sf[2]=new Array("专业知识","第10章 心理治疗概论|第11章 心理治疗师|第12章 心理治疗的共同因素|第13章 精神分析治疗|第14章 个人中心治疗|第15章 行为治疗|第16章 认知治疗|第17章 婚姻与家庭治疗|第18章 其他心理治疗");
sf[3]=new Array("专业实践能力","第19章 焦虑障碍|第20章 躯体形式障碍|第21章 神经衰弱|第22章 分离性障碍-癔症性精神障碍|第23章 应激相关障碍|第24章 进食障碍|第25章 睡眠障碍|第26章 性功能障碍|第27章 性心理障碍|第28章 人格障碍|第29章 心境障碍-情感性精神障碍|第30章 精神分裂症|第31章 自杀问题|第32章 儿童和少年的行为与情绪障碍|第33章 成瘾问题");
sf[4]=new Array("医学伦理学","第0章 医疗机构从业人员行为规范与医学伦理学");
sf[5]=new Array("全部","第1章 心理学基础知识|第2章 人类心理发展|第3章 人格理论|第4章 社会心理学理论|第5章 文化与心理卫生|第6章 精神病学基础|第7章 心理测验与评估|第8章 健康心理学与心身医学基础|第9章 临床心理学研究方法|第10章 心理治疗概论|第11章 心理治疗师|第12章 心理治疗的共同因素|第13章 精神分析治疗|第14章 个人中心治疗|第15章 行为治疗|第16章 认知治疗|第17章 婚姻与家庭治疗|第18章 其他心理治疗|第19章 焦虑障碍|第20章 躯体形式障碍|第21章 神经衰弱|第22章 分离性障碍-癔症性精神障碍|第23章 应激相关障碍|第24章 进食障碍|第25章 睡眠障碍|第26章 性功能障碍|第27章 性心理障碍|第28章 人格障碍|第29章 心境障碍-情感性精神障碍|第30章 精神分裂症|第31章 自杀问题|第32章 儿童和少年的行为与情绪障碍|第33章 成瘾问题");
   var i,j=new Array();
   for(i=0;i<sf.length;i++){
     if(input==sf[i][0].toString()){
       tmpcity=sf[i][1].split("|");
     }
   }
 return tmpcity
}
export const ChinaProvinces = (provinces:string) => {
   // var tmpcity = ref([])
 var tmpcity =[] as string[];
  var sf=new Array();
sf[0]=new Array("广东省","潮州市|东莞市|佛山市|广州市|河源市|惠州市|江门市|揭阳市|茂名市|梅州市|清远市|汕头市|汕尾市|韶关市|深圳市|阳江市|云浮市|湛江市|肇庆市|中山市|珠海市");
sf[1]=new Array("北京市","北京市");
sf[2]=new Array("上海市","上海市");
sf[3]=new Array("天津市","天津市");
sf[4]=new Array("广西省","百色市|北海市|崇左市|防城港市|贵港市|桂林市|河池市|贺州市|来宾市|柳州市|南宁市|钦州市|梧州市|玉林市");
sf[5]=new Array("重庆市","重庆市");
sf[6]=new Array("安徽省","安庆市|蚌埠市|巢湖市|池州市|滁州市|阜阳市|合肥市|淮北市|淮南市|黄山市|六安市|马鞍山市|铜陵市|芜湖市|宿州市|宣城市");
sf[7]=new Array("澳门","澳门");
sf[8]=new Array("福建省","福州市|龙岩|南平市|宁德市|莆田市|泉州市|三明市|厦门市|漳州市");
sf[9]=new Array("甘肃省","白银市|定西地区|甘南藏族自治州|嘉峪关市|金昌市|酒泉地区|兰州市|临夏回族自治州|陇南地区|平凉地区|庆阳地区|天水市|武威地区|张掖地区");
sf[10]=new Array("贵州省","安顺地区|毕节地区|贵阳市|六盘水市|黔东南苗族侗族自治州|黔南布依族苗族自治州|黔西南布依族苗族自治州|铜仁地区|遵义市");
sf[11]=new Array("海南省","海口市|三亚市|省直辖行政单位");
sf[12]=new Array("河北省","保定市|沧州市|承德市|邯郸市|衡水市|廊坊市|秦皇岛市|石家庄市|唐山市|邢台市|张家口市");
sf[13]=new Array("河南省","安阳市|鹤壁市|焦作市|开封市|洛阳市|漯河市|南阳市|平顶山市|濮阳市|三门峡市|商丘市|省直辖行政单位|新乡市|信阳市|许昌市|郑州市|周口市|驻马店地区");
sf[14]=new Array("黑龙江省","大庆市|大兴安岭地区|哈尔滨市|鹤岗市|黑河市|鸡西市|牡丹江市|七台河市|齐齐哈尔市|双鸭山市|绥化市|伊春市");
sf[15]=new Array("湖北省","鄂州市|恩施土家族苗族自治州|黄冈市|黄石市|荆门市|荆州市|省直辖行政单位|十堰市|随州市|武汉市|咸宁市|襄樊市|襄阳市|孝感市|宜昌市");
sf[16]=new Array("湖南省","常德市|郴州市|衡阳市|怀化市|娄底地区|邵阳市|湘潭市|湘西土家族苗族自治州|益阳市|永州市|岳阳市|张家界市|长沙市|株洲市");
sf[17]=new Array("吉林省","白城市|白山市|吉林市|辽源市|四平市|松原市|通化市|延边朝鲜族自治州|长春市");
sf[18]=new Array("江苏省","常州市|淮安市(原淮阴市）|连云港市|南京市|南通市|苏州市|泰州市|无锡市|宿迁市|徐州市|盐城市|扬州市|镇江市");
sf[19]=new Array("江西省","抚州市|赣州市|吉安市|景德镇市|九江市|南昌市|萍乡市|上饶市|新余市|宜春市|鹰潭市");
sf[20]=new Array("辽宁省","鞍山市|本溪市|朝阳市|大连市|丹东市|抚顺市|阜新市|葫芦岛市|锦州市|辽阳市|盘锦市|沈阳市|铁岭市|营口市");
sf[21]=new Array("内蒙古","阿拉善盟|巴彦淖尔盟|包头市|赤峰市|呼和浩特市|呼伦贝尔市|通辽市|乌海市|乌兰察布盟|锡林郭勒盟|兴安盟|伊克昭盟（鄂尔多斯旧称）");
sf[22]=new Array("宁夏省","固原地区|石嘴山市|吴忠市|银川市|中卫市");
sf[23]=new Array("青海省","果洛藏族自治州|海北藏族自治州|海东地区|海南藏族自治州|海西蒙古族藏族自治州|黄南藏族自治州|西宁市|玉树藏族自治州");
sf[24]=new Array("山东省","滨州地区|德州市|东营市|菏泽地区|济南市|济宁市|莱芜市|聊城市|临沂市|青岛市|日照市|泰安市|威海市|潍坊市|烟台市|枣庄市|淄博市");
sf[25]=new Array("山西省","大同市|晋城市|晋中市|临汾市|吕梁市|朔州市|太原市|忻州市|阳泉市|运城市|长治市");
sf[26]=new Array("陕西省","安康地区|宝鸡市|汉中市|商洛地区|铜川市|渭南市|西安市|咸阳市|延安市|榆林地区");
sf[27]=new Array("四川省","阿坝藏族羌族自治州|巴中地区|成都市|达川地区|德阳市|甘孜藏族自治州|广安市|广元市|乐山市|凉山彝族自治州|泸州市|眉山地区|绵阳市|南充市|内江市|攀枝花市|遂宁市|雅安地区|宜宾市|资阳地区|自贡市");
sf[28]=new Array("台湾省","高雄市|基隆市|嘉义市|台北市|台南市|台中市|桃园市|新北市|新竹市|宜兰县");
sf[29]=new Array("西藏省","阿里地区|昌都地区|拉萨市|林芝地区|那曲地区|日喀则地区|山南地区");
sf[30]=new Array("香港","香港");
sf[31]=new Array("新疆省","阿克苏地区|阿勒泰地区|巴音郭楞蒙古自治州|博尔塔拉蒙古自治州|昌吉回族自治州|哈密地区|和田地区|喀什地区|克拉玛依市|克孜勒苏柯尔克孜自治州|省直辖行政单位|塔城地区|吐鲁番地区|乌鲁木齐市|伊犁哈萨克自治州");
sf[32]=new Array("云南省","保山地区|楚雄彝族自治州|大理白族自治州|德宏傣族景颇族自治州|迪庆藏族自治州|红河哈尼族彝族自治州|昆明市|丽江地区|临沧地区|怒江傈僳族自治州|曲靖市|思茅市|文山壮族苗族自治州|西双版纳傣族自治州|玉溪市|昭通地区");
sf[33]=new Array("浙江省","杭州市|湖州市|嘉兴市|金华市|丽水市|宁波市|衢州市|绍兴市|台州市|温州市|舟山市");
    var i,j,cities=new Array();
    for(i=0;i<sf.length;i++){
      if(provinces==sf[i][0].toString()){
        tmpcity=sf[i][1].split("|");
      }
    }

  return tmpcity
}
export const ChinaCitiec = (citiec:string) => {
  var tmpcity =[] as string[];
  var sf=new Array();
  sf[0]=new Array("广州市","白云区|从化市|东山区|番禺市|芳村区|海珠区|花都市|黄埔区|荔湾区|天河区|越秀区|增城市");
  sf[1]=new Array("深圳市","宝安区|福田区|龙岗区|罗湖区|南山区|盐田区");
  sf[2]=new Array("东莞市","东城区|莞城区|南城区|万江区");
  sf[3]=new Array("佛山市","禅城区|高明区|南海区|三水区|顺德区");
  sf[4]=new Array("潮州市","潮安县|饶平县|湘桥区");
  sf[5]=new Array("河源市","东源县|和平县|连平县|龙川县|源城区|紫金县");
  sf[6]=new Array("惠州市","博罗县|惠城区|惠东县|惠阳市|龙门县");
  sf[7]=new Array("江门市","恩平市|鹤山市|江海区|开平市|蓬江区|台山市|新会市");
  sf[8]=new Array("揭阳市","惠来县|揭东县|揭西县|普宁市|榕城区");
  sf[9]=new Array("茂名市","电白县|高州市|化州市|茂港区|茂南区|信宜市");
  sf[10]=new Array("梅州市","大埔县|丰顺县|蕉岭县|梅江区|梅县|平远县|五华县|兴宁市");
  sf[11]=new Array("清远市","佛冈县|连南瑶族自治县|连山壮族瑶族自治县|连州市|清城区|清新县|阳山县|英德市");
  sf[12]=new Array("汕头市","潮南区|潮阳区|澄海区|濠江区|金平区|龙湖区|南澳县");
  sf[13]=new Array("汕尾市","城区|海丰县|陆丰市|陆河县");
  sf[14]=new Array("韶关市","乐昌市|南雄市|曲江区|仁化县|乳源瑶族自治县|始兴县|翁源县|武江区|新丰县|浈江区");
  sf[15]=new Array("阳江市","江城区|阳春市|阳东县|阳西县");
  sf[16]=new Array("云浮市","罗定市|新兴县|郁南县|云安县|云城区");
  sf[17]=new Array("湛江市","赤坎区|雷州市|廉江市|麻章区|坡头区|遂溪县|吴川市|霞山区|徐闻县");
  sf[18]=new Array("肇庆市","德庆县|鼎湖区|端州区|封开县|高要市|广宁县|怀集县|四会市");
  sf[19]=new Array("中山市","中山市");
  sf[20]=new Array("珠海市","斗门区|金湾区|香洲区");
  sf[21]=new Array("北京市","昌平区|朝阳区|崇文区|大兴区|东城区|房山区|丰台区|海淀区|怀柔区|门头沟区|密云县|平谷区|石景山区|顺义区|通州区|西城区|宣武区|延庆县");
  sf[22]=new Array("上海市","宝山区|崇明县|奉贤区|虹口区|黄浦区|嘉定区|金山区|静安区|卢湾区|闵行区|南汇区|浦东新区|普陀区|青浦区|松江区|徐汇区|杨浦区|闸北区|长宁区");
  sf[23]=new Array("天津市","宝坻区|北辰区|大港区|东丽区|汉沽区|和平区|河北区|河东区|河西区|红桥区|蓟县|津南区|静海县|南开区|宁河县|塘沽区|武清区|西青区");
  sf[24]=new Array("重庆市","巴南区|北碚区|璧山县|城口县|大渡口区|大足县|垫江县|丰都县|奉节县|涪陵区|合川市|江北区|江津市|九龙坡区|开县|梁平县|南岸区|南川市|彭水苗族土家族自治县|綦江县|黔江土家族苗族自治县|荣昌县|沙坪坝区|石柱土家族自治县|双桥区|铜梁县|潼南县|万盛区|万州区|巫山县|巫溪县|武隆县|秀山土家族苗族自治县|永川市|酉阳土家族苗族自治县|渝北区|渝中区|云阳县|长寿县|忠县");
  sf[25]=new Array("阿克苏地区","阿克苏市|阿瓦提县|拜城县|柯坪县|库车县|沙雅县|温宿县|乌什县|新和县");
  sf[26]=new Array("阿拉善盟","阿拉善右旗|阿拉善左旗|额济纳旗");
  sf[27]=new Array("阿勒泰地区","阿勒泰市|布尔津县|福海县|富蕴县|哈巴河县|吉木乃县|青河县");
  sf[28]=new Array("阿里地区","措勤县|噶尔县|改则县|革吉县|普兰县|日土县|札达县");
  sf[29]=new Array("安康地区","安康市|白河县|汉阴县|岚皋县|宁陕县|平利县|石泉县|旬阳县|镇坪县|紫阳县");
  sf[30]=new Array("安庆市","枞阳县|大观区|怀宁县|郊区|潜山县|太湖县|桐城市|望江县|宿松县|迎江区|岳西县");
  sf[31]=new Array("安顺地区","安顺市|关岭布依族苗族自治县|平坝县|普定县|镇宁布依族苗族自治县|紫云苗族布依族自治县");
  sf[32]=new Array("安阳市","安阳县|北关区|滑县|郊区|林州市|内黄县|汤阴县|铁西区|文峰区");
  sf[33]=new Array("鞍山市","海城市|立山区|千山区|台安县|铁东区|铁西区|岫岩满族自治县");
  sf[34]=new Array("澳门","澳门");
  sf[35]=new Array("巴彦淖尔盟","磴口县|杭锦后旗|临河市|乌拉特后旗|乌拉特前旗|乌拉特中旗|五原县");
  sf[36]=new Array("巴音郭楞蒙古自治州","博湖县|和静县|和硕县|库尔勒市|轮台县|且末县|若羌县|尉犁县|焉耆回族自治县");
  sf[37]=new Array("巴中地区","巴中市|南江县|平昌县|通江县");
  sf[38]=new Array("白城市","大安市|洮北区|洮南市|通榆县|镇赉县");
  sf[39]=new Array("白山市","八道江区|抚松县|江源县|靖宇县|临江市|长白朝鲜族自治县");
  sf[40]=new Array("白银市","白银区|会宁县|景泰县|靖远县|平川区");
  sf[41]=new Array("百色市","德保县|靖西县|乐业县|凌云县|隆林各族自治县|那坡县|平果县|田东县|田林县|田阳县|西林县|右江区");
  sf[42]=new Array("蚌埠市","东市区|固镇县|怀远县|郊区|五河县|西市区|中市区");
  sf[43]=new Array("包头市","白云矿区|达尔罕茂明安联合旗|东河区|固阳县|郊区|昆都伦区|青山区|石拐矿区|土默特右旗");
  sf[44]=new Array("宝鸡市","宝鸡县|凤县|凤翔县|扶风县|金台区|麟游县|陇县|眉县|岐山县|千阳县|太白县|渭滨区");
  sf[45]=new Array("保定市","安国市|安新县|北市区|博野县|定兴县|定州市|阜平县|高碑店市|高阳县|涞水县|涞源县|蠡县|满城县|南市区|清苑县|曲阳县|容城县|顺平县|唐县|望都县|新市区|雄县|徐水县|易县|涿州市");
  sf[46]=new Array("保山地区","保山市|昌宁县|龙陵县|施甸县|腾冲县");
  sf[47]=new Array("北海市","海城区|合浦县|铁山港区|银海区");
  sf[48]=new Array("本溪市","本溪满族自治县|桓仁满族自治县|明山区|南芬区|平山区|溪湖区");
  sf[49]=new Array("毕节地区","毕节市|大方县|赫章县|金沙县|纳雍县|黔西县|威宁彝族回族苗族自治县|织金县");
  sf[50]=new Array("滨州地区","滨州市|博兴县|惠民县|无棣县|阳信县|沾化县|邹平县");
  sf[51]=new Array("博尔塔拉蒙古自治州","博乐市|精河县|温泉县");
  sf[52]=new Array("沧州市","泊头市|沧县|东光县|海兴县|河间市|黄骅市|孟村回族自治县|南皮县|青县|任丘市|肃宁县|吴桥县|献县|新华区|盐山县|运河区");
  sf[53]=new Array("昌都地区","八宿县|边坝县|察雅县|昌都县|丁青县|贡觉县|江达县|类乌齐县|洛隆县|芒康县|左贡县");
  sf[54]=new Array("昌吉回族自治州","昌吉市|阜康市|呼图壁县|吉木萨尔县|玛纳斯县|木垒哈萨克自治县|奇台县");
  sf[55]=new Array("常德市","安乡县|鼎城区|汉寿县|津市市|澧县|临澧县|石门县|桃源县|武陵区");
  sf[56]=new Array("常州市","郊区|金坛市|溧阳市|戚墅堰区|天宁区|武进市|钟楼区");
  sf[57]=new Array("巢湖市","巢湖区|含山县|和县|庐江县|无为县");
  sf[58]=new Array("朝阳市","北票市|朝阳县|建平县|喀喇沁左翼蒙古族自治县|凌源市|龙城区|双塔区");
  sf[59]=new Array("郴州市","安仁县|北湖区|桂东县|桂阳县|嘉禾县|临武县|汝城县|苏仙区|宜章县|永兴县|资兴市");
  sf[60]=new Array("成都市","成华区|崇州市|大邑县|都江堰市|金牛区|金堂县|锦江区|龙泉驿区|彭州市|郫县|蒲江县|青白江区|青羊区|邛崃市|双流县|温江县|武侯区|新都县|新津县");
  sf[61]=new Array("承德市","承德县|丰宁满族自治县|宽城满族自治县|隆化县|滦平县|平泉县|双滦区|双桥区|围场满族蒙古族自治县|兴隆县|鹰手营子矿区");
  sf[62]=new Array("池州市","东至县|贵池区|青阳县|石台县");
  sf[63]=new Array("赤峰市","阿鲁科尔沁旗|敖汉旗|巴林右旗|巴林左旗|红山区|喀喇沁旗|克什克腾旗|林西县|宁城县|松山区|翁牛特旗|元宝山区");
  sf[64]=new Array("崇左市","大新县|扶绥县|江州区|龙州县|宁明县|凭祥市|天等县");
  sf[65]=new Array("滁州市","定远县|凤阳县|来安县|琅琊区|明光市|南谯区|全椒县|天长市");
  sf[66]=new Array("楚雄彝族自治州","楚雄市|大姚县|禄丰县|牟定县|南华县|双柏县|武定县|姚安县|永仁县|元谋县");
  sf[67]=new Array("达川地区","达川市|达县|大竹县|开江县|渠县|万源市|宣汉县");
  sf[68]=new Array("大理白族自治州","宾川县|大理市|洱源县|鹤庆县|剑川县|弥渡县|南涧彝族自治县|巍山彝族回族自治县|祥云县|漾濞彝族自治县|永平县|云龙县");
  sf[69]=new Array("大连市","甘井子区|金州区|旅顺口区|普兰店市|沙河口区|瓦房店市|西岗区|长海县|中山区|庄河市");
  sf[70]=new Array("大庆市","大同区|杜尔伯特蒙古族自治县|红岗区|林甸县|龙凤区|让胡路区|萨尔图区|肇源县|肇州县");
  sf[71]=new Array("大同市","城区|大同县|广灵县|浑源县|矿区|灵丘县|南郊区|天镇县|新荣区|阳高县|左云县");
  sf[72]=new Array("大兴安岭地区","呼玛县|漠河县|塔河县");
  sf[73]=new Array("丹东市","东港市|凤城市|宽甸满族自治县|元宝区|振安区|振兴区");
  sf[74]=new Array("德宏傣族景颇族自治州","梁河县|陇川县|潞西市|瑞丽市|畹町市|盈江县");
  sf[75]=new Array("德阳市","广汉市|旌阳区|罗江县|绵竹市|什邡市|中江县");
  sf[76]=new Array("德州市","德城区|乐陵市|临邑县|陵县|宁津县|平原县|齐河县|庆云县|武城县|夏津县|禹城市");
  sf[77]=new Array("迪庆藏族自治州","德钦县|维西傈僳族自治县|中甸县");
  sf[78]=new Array("定西地区","定西县|临洮县|陇西县|岷县|通渭县|渭源县|漳县");
  sf[79]=new Array("东营市","东营区|广饶县|河口区|垦利县|利津县");
  sf[80]=new Array("鄂州市","鄂城区|华容区|梁子湖区");
  sf[81]=new Array("恩施土家族苗族自治州","巴东县|恩施市|鹤峰县|建始县|来凤县|利川市|咸丰县|宣恩县");
  sf[82]=new Array("防城港市","东兴市|防城区|港口区|上思县");
  sf[83]=new Array("福州市","仓山区|福清市|鼓楼区|晋安区|连江县|罗源县|马尾区|闽侯县|闽清县|平潭县|台江区|永泰县|长乐市");
  sf[84]=new Array("抚顺市","抚顺县|露天区|清原满族自治县|顺城区|望花区|新宾满族自治县|新抚区");
  sf[85]=new Array("抚州市","崇仁县|东乡县|广昌县|金溪县|乐安县|黎川县|临川市|南城县|南丰县|宜黄县|资溪县");
  sf[86]=new Array("阜新市","阜新蒙古族自治县|海州区|清河门区|太平区|细河区|新邱区|彰武县");
  sf[87]=new Array("阜阳市","阜南县|界首市|临泉县|太和县|颍东区|颍泉区|颍上县|颍州区");
  sf[88]=new Array("甘南藏族自治州","迭部县|合作市|临潭县|碌曲县|玛曲县|夏河县|舟曲县|卓尼县");
  sf[89]=new Array("甘孜藏族自治州","巴塘县|白玉县|丹巴县|道孚县|稻城县|得荣县|德格县|甘孜县|九龙县|康定县|理塘县|炉霍县|泸定县|色达县|石渠县|乡城县|新龙县|雅江县");
  sf[90]=new Array("赣州市","安远县|崇义县|大余县|定南县|赣县|会昌县|龙南县|南康市|宁都县|全南县|瑞金市|上犹县|石城县|信丰县|兴国县|寻乌县|于都县|章贡区");
  sf[91]=new Array("高雄市","高雄市");
  sf[92]=new Array("固原地区","泾源县|隆德县|彭阳县|西吉县|原州区");
  sf[93]=new Array("广安市","广安区|华莹市|邻水县|武胜县|岳池县");
  sf[94]=new Array("广元市","苍溪县|朝天区|剑阁县|利州区|青川县|旺苍县|元坝区");
  sf[95]=new Array("贵港市","港北区|港南区|桂平市|平南县|覃塘区");
  sf[96]=new Array("贵阳市","白云区|花溪区|开阳县|南明区|清镇市|乌当区|息烽县|小河区|修文县|云岩区");
  sf[97]=new Array("桂林市","叠彩区|恭城瑶族自治县|灌阳县|荔蒲县|临桂县|灵川县|龙胜各县自治区|平乐县|七星区|全州县|象山区|兴安县|秀峰区|雁山区|阳朔县|永福县|资源县");
  sf[98]=new Array("果洛藏族自治州","班玛县|达日县|甘德县|久治县|玛多县|玛沁县");
  sf[99]=new Array("哈尔滨市","阿成区|巴彦县|宾县|道里区|道外区|方正县|呼兰区|木兰县|南岗区|平房区|尚志市|双城市|松北区|通河县|五常市|香坊区|延寿县|依兰县");
  sf[100]=new Array("哈密地区","巴里坤哈萨克自治县|哈密市|伊吾县");
  sf[101]=new Array("海北藏族自治州","刚察县|海晏县|门源回族自治县|祁连县");
  sf[102]=new Array("海东地区","互助土族自治县|化隆回族自治县|湟源县|湟中县|乐都县|民和回族土族自治县|平安县|循化撒拉族自治县");
  sf[103]=new Array("海口市","龙华区|美兰区|琼山区|秀英区");
  sf[104]=new Array("海南藏族自治州","共和县|贵德县|贵南县|同德县|兴海县");
  sf[105]=new Array("海西蒙古族藏族自治州","德令哈市|都兰县|格尔木市|天峻县|乌兰县");
  sf[106]=new Array("邯郸市","成安县|磁县|丛台区|大名县|肥乡县|峰峰矿区|复兴区|馆陶县|广平县|邯郸县|邯山区|鸡泽县|临漳县|邱县|曲周县|涉县|魏县|武安市|永年县");
  sf[107]=new Array("汉中市","城固县|佛坪县|汉台区|留坝县|略阳县|勉县|南郑县|宁强县|西乡县|洋县|镇巴县");
  sf[108]=new Array("杭州市","滨江区|淳安县|富阳市|拱墅区|建德市|江干区|临安市|上城区|桐庐县|西湖区|下城区|萧山市|余杭市");
  sf[109]=new Array("合肥市","东市区|肥东县|肥西县|郊区|西市区|长丰县|中市区");
  sf[110]=new Array("和田地区","策勒县|和田市|和田县|洛浦县|民丰县|墨玉县|皮山县|于田县");
  sf[111]=new Array("河池市","巴马瑶族自治县|大化瑶族自治县|东兰县|都安瑶族自治县|凤山县|环江毛南族自治县|金城江区|罗城仫佬族自治县|南丹县|天峨县|宜州市");
  sf[112]=new Array("菏泽地区","曹县|成武县|单县|定陶县|东明县|菏泽市|巨野县|鄄城县|郓城县");
  sf[113]=new Array("贺州市","八步区|富川瑶族自治县|昭平县|钟山县");
  sf[114]=new Array("鹤壁市","鹤山区|郊区|浚县|淇县|山城区");
  sf[115]=new Array("鹤岗市","东山区|工农区|萝北县|南山区|绥滨县|向阳区|兴安区|兴山区");
  sf[116]=new Array("黑河市","爱辉区|北安市|嫩江县|孙吴县|五大连池市|逊克县");
  sf[117]=new Array("衡水市","安平县|阜城县|故城县|冀州市|景县|饶阳县|深州市|桃城区|武强县|武邑县|枣强县");
  sf[118]=new Array("衡阳市","常宁市|城北区|城南区|衡东县|衡南县|衡山县|衡阳县|江东区|郊 区|耒阳市|南岳区|祁东县");
  sf[119]=new Array("红河哈尼族彝族自治州","个旧市|河口瑶族自治县|红河县|建水县|金平苗族瑶族傣族自治县|开远市|泸西县|绿春县|蒙自县|弥勒县|屏边苗族自治县|石屏县|元阳县");
  sf[120]=new Array("呼和浩特市","和林格尔县|回民区|郊区|清水河县|土默特左旗|托克托县|武川县|新城区|玉泉区");
  sf[121]=new Array("呼伦贝尔市","阿荣旗|陈巴尔虎旗|额尔古纳市|鄂伦春自治旗|鄂温克族自治旗|根河市|海拉尔市|满洲里市|莫力达瓦达斡尔族自治旗|新巴尔虎右旗|新巴尔虎左旗|牙克石市|扎兰屯市");
  sf[122]=new Array("葫芦岛市","建昌县|连山区|龙港区|南票区|绥中县|兴城市");
  sf[123]=new Array("湖州市","安吉县|德清县|南浔区|吴兴区|长兴县");
  sf[124]=new Array("怀化市","辰溪县|鹤城区|洪江市|会同县|靖州苗族侗族自治县|麻阳苗族自治县|通道侗族自治县|新晃侗族自治县|溆浦县|沅陵县|芷江侗族自治县|中方县");
  sf[125]=new Array("淮安市(原淮阴市）","洪泽县|淮安市|淮阴县|金湖县|涟水县|清河区|清浦区|盱眙县");
  sf[126]=new Array("淮北市","杜集区|烈山区|濉溪县|相山区");
  sf[127]=new Array("淮南市","八公山区|大通区|凤台县|潘集区|田家庵区|谢家集区");
  sf[128]=new Array("黄冈市","红安县|黄梅县|黄州区|罗田县|麻城市|蕲春县|团风县|武穴市|浠水县|英山县");
  sf[129]=new Array("黄南藏族自治州","河南蒙古族自治县|尖扎县|同仁县|泽库县");
  sf[130]=new Array("黄山市","黄山区|徽州区|祁门县|屯溪区|歙县|休宁县|黟县");
  sf[131]=new Array("黄石市","大冶市|黄石港区|石灰窑区|铁山区|下陆区|阳新县");
  sf[132]=new Array("鸡西市","城子河区|滴道区|恒山区|虎林市|鸡东县|鸡冠区|梨树区|麻山区|密山市");
  sf[133]=new Array("基隆市","基隆市");
  sf[134]=new Array("吉安市","安福县|吉安市|吉安县|吉水县|井冈山市|宁冈县|遂川县|泰和县|万安县|峡江县|新干县|永丰县|永新县");
  sf[135]=new Array("吉林市","昌邑区|船营区|丰满区|桦甸市|蛟河市|龙潭区|磐石市|舒兰市|永吉县");
  sf[136]=new Array("济南市","槐荫区|济阳县|历城区|历下区|平阴县|商河县|市中区|天桥区|章丘市|长清县");
  sf[137]=new Array("济宁市","嘉祥县|金乡县|梁山县|曲阜市|任城区|市中区|泗水县|微山县|汶上县|兖州市|鱼台县|邹城市");
  sf[138]=new Array("嘉兴市","海宁市|海盐县|嘉善县|郊区|平湖市|桐乡市|秀城区");
  sf[139]=new Array("嘉义市","嘉义市");
  sf[140]=new Array("嘉峪关市","市辖区");
  sf[141]=new Array("焦作市","博爱县|解放区|马村区|孟州市|沁阳市|山阳区|温县|武陟县|修武县|中站区");
  sf[142]=new Array("金昌市","金川区|永昌县");
  sf[143]=new Array("金华市","东阳市|金华县|兰溪市|磐安县|浦江县|武义县|婺城区|义乌市|永康市");
  sf[144]=new Array("锦州市","北宁市|古塔区|黑山县|凌海市|凌河区|太和区|义县");
  sf[145]=new Array("晋城市","城区|高平市|陵川县|沁水县|阳城县|泽州县");
  sf[146]=new Array("晋中市","和顺县|介休市|灵石县|平遥县|祁县|寿阳县|太谷县|昔阳县|榆次市|榆社县|左权县");
  sf[147]=new Array("荆门市","东宝区|掇刀区|京山县|沙洋县|钟祥市");
  sf[148]=new Array("荆州市","公安县|洪湖市|监利县|江陵县|荆州区|沙市区|石首市|松滋市");
  sf[149]=new Array("景德镇市","昌江区|浮梁县|乐平市|珠山区");
  sf[150]=new Array("九江市","德安县|都昌县|湖口县|九江县|庐山区|彭泽县|瑞昌市|武宁县|星子县|修水县|浔阳区|永修县");
  sf[151]=new Array("酒泉地区","阿克塞哈萨克族自治县|安西县|敦煌市|金塔县|酒泉市|肃北蒙古族自治县|玉门市");
  sf[152]=new Array("喀什地区","巴楚县|伽师县|喀什市|麦盖提县|莎车县|疏附县|疏勒县|塔什库尔干塔吉克自治县|叶城县|英吉沙县|岳普湖县|泽普县");
  sf[153]=new Array("开封市","鼓楼区|郊区|开封县|兰考县|龙亭区|南关区|杞县|顺河回族区|通许县|尉氏县");
  sf[154]=new Array("克拉玛依市","白碱滩区|独山子区|克拉玛依区|乌尔禾区");
  sf[155]=new Array("克孜勒苏柯尔克孜自治州","阿合奇县|阿克陶县|阿图什市|乌恰县");
  sf[156]=new Array("昆明市","安宁市|呈贡县|东川区|富民县|官渡区|晋宁县|禄劝彝族苗族自治县|盘龙区|石林彝族自治县|嵩明县|五华区|西山区|寻甸回族彝族自治县|宜良县");
  sf[157]=new Array("拉萨市","城关区|达孜县|当雄县|堆龙德庆县|林周县|墨竹工卡县|尼木县|曲水县");
  sf[158]=new Array("来宾市","合山市|金秀瑶族自治县|武宣县|象州县|忻城县|兴宾区");
  sf[159]=new Array("莱芜市","钢城区|莱城区");
  sf[160]=new Array("兰州市","安宁区|城关区|皋兰县|红古区|七里河区|西固区|永登县|榆中县");
  sf[161]=new Array("廊坊市","安次区|霸州市|大厂回族自治县|大城县|固安县|三河市|文安县|香河县|永清县");
  sf[162]=new Array("乐山市","峨边彝族自治县|峨眉山市|夹江县|犍为县|金口河区|井研县|马边彝族自治县|沐川县|沙湾区|市中区|五通桥区");
  sf[163]=new Array("丽江地区","华坪县|丽江纳西族自治县|宁蒗彝族自治县|永胜县");
  sf[164]=new Array("丽水市","缙云县|景宁畲族自治县|丽水市|龙泉市|青田县|庆元县|松阳县|遂昌县|云和县");
  sf[165]=new Array("连云港市","东海县|赣榆县|灌南县|灌云县|海州区|连云区|新浦区|云台区");
  sf[166]=new Array("凉山彝族自治州","布拖县|德昌县|甘洛县|会东县|会理县|金阳县|雷波县|美姑县|冕宁县|木里藏族自治县|宁南县|普格县|西昌市|喜德县|盐源县|越西县|昭觉县");
  sf[167]=new Array("辽阳市","白塔区|灯塔市|弓长岭区|宏伟区|辽阳县|太子河区|文圣区");
  sf[168]=new Array("辽源市","东丰县|东辽县|龙山区|西安区");
  sf[169]=new Array("聊城市","茌平县|东阿县|东昌府区|高唐县|冠县|临清市|莘县|阳谷县");
  sf[170]=new Array("林芝地区","波密县|察隅县|工布江达县|朗县|林芝县|米林县|墨脱县");
  sf[171]=new Array("临沧地区","沧源佤族自治县|凤庆县|耿马傣族佤族自治县|临沧县|双江拉祜族佤族布朗族傣族自治县|永德县|云县|镇康县");
  sf[172]=new Array("临汾市","安泽县|大宁县|汾西县|浮山县|古县|洪洞县|侯马市|霍州市|吉县|临汾市|蒲县|曲沃县|隰县|乡宁县|襄汾县|翼城县|永和县");
  sf[173]=new Array("临夏回族自治州","东乡族自治县|广河县|和政县|积石山保安族东乡族撒拉族自治县|康乐县|临夏市|临夏县|永靖县");
  sf[174]=new Array("临沂市","苍山县|费县|河东区|莒南县|兰山区|临沭县|罗庄区|蒙阴县|平邑县|郯城县|沂南县|沂水县");
  sf[175]=new Array("柳州市","城中区|柳北区|柳城县|柳江县|柳南区|鹿寨县|融安县|融水苗族自治县|三江侗族自治县|鱼峰区");
  sf[176]=new Array("六安市","霍邱县|霍山县|金安区|金寨县|寿县|舒城县|裕安区");
  sf[177]=new Array("六盘水市","六枝特区|盘县特区|水城县|钟山区");
  sf[178]=new Array("龙岩","连城县|上杭县|武平县|新罗区|永定县|漳平市|长汀县");
  sf[179]=new Array("陇南地区","成县|宕昌县|徽县|康县|礼县|两当县|文县|武都县|西和县");
  sf[180]=new Array("娄底地区","冷水江市|涟源市|娄底市|双峰县|新化县");
  sf[181]=new Array("泸州市","古蔺县|合江县|江阳区|龙马潭区|泸县|纳溪区|叙永县");
  sf[182]=new Array("洛阳市","廛河回族区|吉利区|涧西区|郊区|老城区|栾川县|洛宁县|孟津县|汝阳县|嵩县|西工区|新安县|偃师市|伊川县|宜阳县");
  sf[183]=new Array("漯河市","临颍县|舞阳县|郾城区|源汇区|召陵区");
  sf[184]=new Array("吕梁市","方山县|汾阳市|交城县|交口县|岚县|离石区|临县|柳林县|石楼县|文水县|孝义市|兴县|中阳县");
  sf[185]=new Array("马鞍山市","当涂县|花山区|金家庄区|雨山区");
  sf[186]=new Array("眉山地区","丹棱县|洪雅县|眉山县|彭山县|青神县|仁寿县");
  sf[187]=new Array("绵阳市","安县|北川县|涪城区|江油市|平武县|三台县|盐亭县|游仙区|梓潼县");
  sf[188]=new Array("牡丹江市","爱民区|东安区|东宁县|海林市|林口县|穆棱市|宁安市|绥芬河市|西安区|阳明区");
  sf[189]=new Array("那曲地区","安多县|巴青县|班戈县|比如县|嘉黎县|那曲县|尼玛县|聂荣县|申扎县|索县");
  sf[190]=new Array("南昌市","安义县|东湖区|郊区|进贤县|南昌县|青云谱区|湾里区|西湖区|新建县");
  sf[191]=new Array("南充市","高坪区|嘉陵区|阆中市|南部县|蓬安县|顺庆区|西充县|仪陇县|营山县");
  sf[192]=new Array("南京市","白下区|高淳县|鼓楼区|建邺区|江宁区|溧水县|六合区|浦口区|栖霞区|秦淮区|下关区|玄武区|雨花台区");
  sf[193]=new Array("南宁市","宾阳县|横县|江南区|良庆区|隆安县|马山县|青秀区|上林县|武鸣县|西乡塘区|兴宁区|邕宁区");
  sf[194]=new Array("南平市","光泽县|建瓯市|建阳市|浦城县|邵武市|顺昌县|松溪县|武夷山市|延平区|政和县");
  sf[195]=new Array("南通市","崇川区|港闸区|海安县|海门市|启东市|如东县|如皋市|通州市");
  sf[196]=new Array("南阳市","邓州市|方城县|南召县|内乡县|社旗县|唐河县|桐柏县|宛城区|卧龙区|西峡县|淅川县|新野县|镇平县");
  sf[197]=new Array("内江市","东兴区|隆昌县|市中区|威远县|资中县");
  sf[198]=new Array("宁波市","北仑区|慈溪市|奉化市|海曙区|江北区|江东区|宁海县|象山县|鄞县|余姚市|镇海区");
  sf[199]=new Array("宁德市","福安市|福鼎市|古田县|蕉城区|屏南县|寿宁县|霞浦县|柘荣县|周宁县");
  sf[200]=new Array("怒江傈僳族自治州","福贡县|贡山独龙族怒族自治县|兰坪白族普米族自治县|泸水县");
  sf[201]=new Array("攀枝花市","东区|米易县|仁和区|西区|盐边县");
  sf[202]=new Array("盘锦市","大洼县|盘山县|双台子区|兴隆台区");
  sf[203]=new Array("平顶山市","宝丰县|郏县|鲁山县|汝州市|石龙区|卫东区|舞钢市|新华区|叶县|湛河区");
  sf[204]=new Array("平凉地区","崇信县|华亭县|泾川县|静宁县|灵台县|平凉市|庄浪县");
  sf[205]=new Array("萍乡市","安源区|莲花县|芦溪县|上栗县|湘东区");
  sf[206]=new Array("莆田市","城厢区|涵江区|荔城区|仙游县|秀屿区");
  sf[207]=new Array("濮阳市","范县|华龙区|南乐县|濮阳县|清丰县|台前县");
  sf[208]=new Array("七台河市","勃利县|茄子河区|桃山区|新兴区");
  sf[209]=new Array("齐齐哈尔市","昂昂溪区|拜泉县|富拉尔基区|富裕县|甘南县|建华区|克东县|克山县|龙江县|龙沙区|梅里斯达斡尔族区|讷河市|碾子山区|泰来县|铁锋区|依安县");
  sf[210]=new Array("黔东南苗族侗族自治州","岑巩县|从江县|丹寨县|黄平县|剑河县|锦屏县|凯里市|雷山县|黎平县|麻江县|榕江县|三穗县|施秉县|台江县|天柱县|镇远县");
  sf[211]=new Array("黔南布依族苗族自治州","都匀市|独山县|福泉市|贵定县|惠水县|荔波县|龙里县|罗甸县|平塘县|三都水族自治县|瓮安县|长顺县");
  sf[212]=new Array("黔西南布依族苗族自治州","安龙县|册亨县|普安县|晴隆县|望谟县|兴仁县|兴义市|贞丰县");
  sf[213]=new Array("钦州市","灵山县|浦北县|钦北区|钦南区");
  sf[214]=new Array("秦皇岛市","北戴河区|昌黎县|抚宁县|海港区|卢龙县|青龙满族自治县|山海关区");
  sf[215]=new Array("青岛市","城阳区|黄岛区|即墨市|胶南市|胶州市|莱西市|崂山区|李沧区|平度市|市北区|市南区|四方区");
  sf[216]=new Array("庆阳地区","合水县|华池县|环县|宁县|庆阳县|西峰市|镇原县|正宁县");
  sf[217]=new Array("衢州市","常山县|江山市|开化县|柯城区|龙游县|衢县");
  sf[218]=new Array("曲靖市","富源县|会泽县|陆良县|罗平县|马龙县|麒麟区|师宗县|宣威市|沾益县");
  sf[219]=new Array("泉州市","安溪县|德化县|丰泽区|惠安县|金门县|晋江市|鲤城区|洛江区|南安市|泉港区|石狮市|永春县");
  sf[220]=new Array("日喀则地区","昂仁县|白朗县|定结县|定日县|岗巴县|吉隆县|江孜县|康马县|拉孜县|南木林县|聂拉木县|仁布县|日喀则市|萨嘎县|萨迦县|谢通门县|亚东县|仲巴县");
  sf[221]=new Array("日照市","东港区|莒县|岚山区|五莲县");
  sf[222]=new Array("三门峡市","湖滨区|灵宝市|卢氏县|渑池县|陕县|义马市");
  sf[223]=new Array("三明市","大田县|建宁县|将乐县|梅列区|明溪县|宁化县|清流县|三元区|沙　县|泰宁县|永安市|尤溪县");
  sf[224]=new Array("三亚市","市辖区");
  sf[225]=new Array("厦门市","海沧区|湖里区|集美区|思明区?|同安区|翔安区");
  sf[226]=new Array("山南地区","措美县|错那县|贡嘎县|加查县|浪卡子县|隆子县|洛扎县|乃东县|琼结县|曲松县|桑日县|扎囊县");
  sf[227]=new Array("商洛地区","丹凤县|洛南县|山阳县|商南县|商州市|柞水县|镇安县");
  sf[228]=new Array("商丘市","梁园区|民权县|宁陵县|睢县|睢阳区|夏邑县|永城市|虞城县|柘城县");
  sf[229]=new Array("上饶市","波阳县|德兴市|广丰县|横峰县|铅山县|上饶市|上饶县|万年县|婺源县|弋阳县|余干县|玉山县");
  sf[230]=new Array("邵阳市","北塔区|城步苗族自治县|大祥区|洞口县|隆回县|邵东县|邵阳县|双清区|绥宁县|武冈市|新宁县|新邵县");
  sf[231]=new Array("绍兴市","上虞市|绍兴县|嵊州市|新昌县|越城区|诸暨市");
  sf[232]=new Array("沈阳市","大东区|东陵区|法库县|和平区|皇姑区|康平县|辽中县|沈河区|苏家屯区|铁西区|新城子区|新民市|于洪区");
  sf[233]=new Array("省直辖行政单位","阿拉尔市|白沙黎族自治县|保亭黎族苗族自治县|昌江黎族自治县|澄迈县|儋州市|定安县|东方市|济源市|乐东黎族自治县|临高县|陵水黎族自治县|潜江市|琼海市|琼中黎族苗族自治县|神农架林区|石河子市|天门市|图木舒克市|屯昌县|万宁市|文昌市|五家渠市|五指山市|西南中沙群岛办事处|仙桃市");
  sf[234]=new Array("十堰市","丹江口市|房县|茅箭区|郧西县|郧县|张湾区|竹山县|竹溪县");
  sf[235]=new Array("石家庄市","高邑县|藁城市|行唐县|郊区|晋州市|井陉矿区|井陉县|灵寿县|鹿泉市|栾城县|平山县|桥东区|桥西区|深泽县|无极县|辛集市|新华区|新乐市|元氏县|赞皇县|长安区|赵县|正定县");
  sf[236]=new Array("石嘴山市","大武口区|惠农区|平罗县");
  sf[237]=new Array("双鸭山市","宝清县|宝山区|集贤县|尖山区|岭东区|饶河县|四方台区|友谊县");
  sf[238]=new Array("朔州市","怀仁县|平鲁区|山阴县|朔城区|应县|右玉县");
  sf[239]=new Array("思茅市","江城哈尼族彝族自治县|景东彝族自治县|景谷傣族彝族自治县|澜沧拉祜族自治县|孟连傣族拉祜族佤族自治县|墨江哈尼族自治县|普洱哈尼族彝族自治县|思茅市|西盟佤族自治县|镇沅彝族哈尼族拉祜族自治县");
  sf[240]=new Array("四平市","公主岭市|梨树县|双辽市|铁东区|铁西区|伊通满族自治县");
  sf[241]=new Array("松原市","扶余县|宁江区|前郭尔罗斯蒙古族自治县|乾安县|长岭县");
  sf[242]=new Array("苏州市","沧浪区|常熟市|郊区|金阊区|昆山市|平江区|太仓市|吴江市|吴县市|张家港市");
  sf[243]=new Array("绥化市","安达市|海伦市|兰西县|明水县|青冈县|庆安县|绥化市|绥棱县|望奎县|肇东市");
  sf[244]=new Array("随州市","曾都区|广水市|随县");
  sf[245]=new Array("遂宁市","安居区|船山区|大英县|蓬溪县|射洪县");
  sf[246]=new Array("塔城地区","额敏县|和布克赛尔蒙古自治县|沙湾县|塔城市|托里县|乌苏市|裕民县");
  sf[247]=new Array("台北市","台北市");
  sf[248]=new Array("台南市","台南市");
  sf[249]=new Array("台中市","台中市");
  sf[250]=new Array("台州市","黄岩区|椒江区|临海市|路桥区|三门县|天台县|温岭市|仙居县|玉环县");
  sf[251]=new Array("太原市","古交市|尖草坪区|晋源区|娄烦县|清徐县|万柏林区|小店区|杏花岭区|阳曲县|迎泽区");
  sf[252]=new Array("泰安市","东平县|肥城市|郊区|宁阳县|泰山区|新泰市");
  sf[253]=new Array("泰州市","高港区|海陵区|姜堰市|靖江市|泰兴市|兴化市");
  sf[254]=new Array("唐山市","丰南市|丰润县|古冶区|开平区|乐亭县|路北区|路南区|滦南县|滦县|迁安市|迁西县|唐海县|新区|玉田县|遵化市");
  sf[255]=new Array("桃园市","桃园市");
  sf[256]=new Array("天水市","北道区|甘谷县|秦安县|秦城区|清水县|武山县|张家川回族自治县");
  sf[257]=new Array("铁岭市","昌图县|开原市|清河区|铁法市|铁岭县|西丰县|银州区");
  sf[258]=new Array("通化市","东昌区|二道江区|辉南县|集安市|柳河县|梅河口市|通化县");
  sf[259]=new Array("通辽市","霍林郭勒市|开鲁县|科尔沁区|科尔沁左翼后旗|科尔沁左翼中旗|库伦旗|奈曼旗|扎鲁特旗");
  sf[260]=new Array("铜川市","城区|郊区|耀县|宜君县");
  sf[261]=new Array("铜陵市","郊区|狮子山区|铜官山区|铜陵县");
  sf[262]=new Array("铜仁地区","德江县|江口县|石阡县|思南县|松桃苗族自治县|铜仁市|万山特区|沿河土家族自治县|印江土家族苗族自治县|玉屏侗族自治县");
  sf[263]=new Array("吐鲁番地区","鄯善县|吐鲁番市|托克逊县");
  sf[264]=new Array("威海市","环翠区|荣成市|乳山市|文登市");
  sf[265]=new Array("潍坊市","安丘市|昌乐县|昌邑市|坊子区|高密市|寒亭区|奎文区|临朐县|青州市|寿光市|潍城区|诸城市");
  sf[266]=new Array("渭南市","白水县|澄城县|大荔县|富平县|韩城市|合阳县|华县|华阴市|临渭区|蒲城县|潼关县");
  sf[267]=new Array("温州市","苍南县|洞头县|乐清市|龙湾区|鹿城区|瓯海区|平阳县|瑞安市|泰顺县|文成县|永嘉县");
  sf[268]=new Array("文山壮族苗族自治州","富宁县|广南县|麻栗坡县|马关县|丘北县|文山县|西畴县|砚山县");
  sf[269]=new Array("乌海市","海勃湾区|海南区|乌达区");
  sf[270]=new Array("乌兰察布盟","察哈尔右翼后旗|察哈尔右翼前旗|察哈尔右翼中旗|丰镇市|化德县|集宁市|凉城县|商都县|四子王旗|兴和县|卓资县");
  sf[271]=new Array("乌鲁木齐市","东山区|南山矿区|沙依巴克区|水磨沟区|天山区|头屯河区|乌鲁木齐县|新市区");
  sf[272]=new Array("无锡市","北塘区|崇安区|江阴市|郊区|马山区|南长区|锡山市|宜兴市");
  sf[273]=new Array("芜湖市","繁昌县|镜湖区|鸠江区|马塘区|南陵县|芜湖县|新芜区");
  sf[274]=new Array("吴忠市","红寺堡区|利通区|青铜峡市|同心县|盐池县");
  sf[275]=new Array("梧州市","苍梧县|岑溪市|蝶山区|蒙山县|市郊区|藤县|万秀区");
  sf[276]=new Array("武汉市","蔡甸区|东西湖区|汉南区|汉阳区|洪山区|黄陂区|江岸区|江汉区|江夏区|乔口区|青山区|武昌区|新洲区");
  sf[277]=new Array("武威地区","古浪县|民勤县|天祝藏族自治县|武威市");
  sf[278]=new Array("西安市","灞桥区|碑林区|高陵县|户县|蓝田县|莲湖区|临潼区|未央区|新城区|阎良区|雁塔区|长安县|周至县");
  sf[279]=new Array("西宁市","城北区|城东区|城西区|城中区|大通回族土族自治县");
  sf[280]=new Array("西双版纳傣族自治州","景洪市|勐海县|勐腊县");
  sf[281]=new Array("锡林郭勒盟","阿巴嘎旗|东乌珠穆沁旗|多伦县|二连浩特市|苏尼特右旗|苏尼特左旗|太仆寺旗|西乌珠穆沁旗|锡林浩特市|镶黄旗|正蓝旗|正镶白旗");
  sf[282]=new Array("咸宁市","赤壁市|崇阳县|嘉鱼县|通城县|通山县|咸安区");
  sf[283]=new Array("咸阳市","彬县|淳化县|泾阳县|礼泉县|乾县|秦都区|三原县|渭城区|武功县|兴平市|旬邑县|杨陵区|永寿县|长武县");
  sf[284]=new Array("香港","香港");
  sf[285]=new Array("湘潭市","韶山市|湘潭县|湘乡市|雨湖区|岳塘区");
  sf[286]=new Array("湘西土家族苗族自治州","保靖县|凤凰县|古丈县|花垣县|吉首市|龙山县|泸溪县|永顺县");
  sf[287]=new Array("襄樊市","保康县|樊城区|谷城县|老河口市|南漳县|襄城区|宜城市|枣阳市");
  sf[288]=new Array("襄阳市","襄阳县");
  sf[289]=new Array("孝感市","安陆市|大悟县|汉川市|孝昌县|孝南区|应城市|云梦县");
  sf[290]=new Array("忻州市","保德县|代县|定襄县|繁峙县|河曲县|静乐县|岢岚县|宁武县|偏关县|神池县|五台县|五寨县|忻府区|原平市");
  sf[291]=new Array("新北市","新北市");
  sf[292]=new Array("新乡市","北站区|封丘县|红旗区|辉县市|获嘉县|郊区|卫辉市|新华区|新乡县|延津县|原阳县|长垣县");
  sf[293]=new Array("新余市","分宜县|渝水区");
  sf[294]=new Array("新竹市","新竹市");
  sf[295]=new Array("信阳市","负忧|固始县|光山县|淮滨县|潢川县|罗山县|平桥区|商城县|息县|新县");
  sf[296]=new Array("邢台市","柏乡县|广宗县|巨鹿县|临城县|临西县|隆尧县|南宫市|南和县|内丘县|宁晋县|平乡县|桥东区|桥西区|清河县|任县|沙河市|威县|新河县|邢台县");
  sf[297]=new Array("兴安盟","阿尔山市|科尔沁右翼前旗|科尔沁右翼中旗|突泉县|乌兰浩特市|扎赉特旗");
  sf[298]=new Array("宿迁市","沭阳县|泗洪县|泗阳县|宿城区|宿豫县");
  sf[299]=new Array("宿州市","砀山县|灵璧县|泗县|萧县|墉桥区");
  sf[300]=new Array("徐州市","丰县|鼓楼区|贾汪区|九里区|沛县|邳州市|泉山区|睢宁县|铜山县|新沂市|云龙区");
  sf[301]=new Array("许昌市","魏都区|襄城县|许昌县|鄢陵县|禹州市|长葛市");
  sf[302]=new Array("宣城市","广德县|绩溪县|泾县|旌德县|郎溪县|宁国市|宣州区");
  sf[303]=new Array("雅安地区","宝兴县|汉源县|芦山县|名山县|石棉县|天全县|雅安市|荥经县");
  sf[304]=new Array("烟台市","福山区|海阳市|莱山区|莱阳市|莱州市|龙口市|牟平区|蓬莱市|栖霞市|长岛县|招远市|芝罘区");
  sf[305]=new Array("延安市","安塞县|宝塔区|富县|甘泉县|黄陵县|黄龙县|洛川县|吴旗县|延川县|延长县|宜川县|志丹县|子长县");
  sf[306]=new Array("延边朝鲜族自治州","安图县|敦化市|和龙市|珲春市|龙井市|图们市|汪清县|延吉市");
  sf[307]=new Array("盐城市","滨海县|城区|大丰市|东台市|阜宁县|建湖县|射阳县|响水县|盐都县");
  sf[308]=new Array("扬州市","宝应县|高邮市|广陵区|邗江县|江都市|郊区|仪征市");
  sf[309]=new Array("阳泉市","城区|郊区|矿区|平定县|盂县");
  sf[310]=new Array("伊春市","翠峦区|带岭区|东风区|抚远县|富锦市|红星区|桦川县|桦南县|嘉荫县|郊区|金山屯区|美溪区|南岔区|前进区|上甘岭区|汤旺河区|汤原县|铁力市|同江市|乌马河区|乌伊岭区|五营区|西林区|向阳区|新青区|伊春区|友好区");
  sf[311]=new Array("伊克昭盟（鄂尔多斯旧称）","达拉特旗|东胜市|鄂托克旗|鄂托克前旗|杭锦旗|乌审旗|伊金霍洛旗|准格尔旗");
  sf[312]=new Array("伊犁哈萨克自治州","察布查尔锡伯自治县|巩留县|霍城县|奎屯市|尼勒克县|特克斯县|新源县|伊犁地区|伊宁市|伊宁县|昭苏县");
  sf[313]=new Array("宜宾市","翠屏区|高县|珙县|江安县|筠连县|南溪县|屏山县|兴文县|宜宾县|长宁县");
  sf[314]=new Array("宜昌市","当阳市|点军区|虎亭区|五峰土家族自治县|伍家岗区|西陵区|兴山县|宜昌县|宜都市|远安县|长阳土家族自治县|枝江市|秭归县");
  sf[315]=new Array("宜春市","丰城市|奉新县|高安市|靖安县|上高县|铜鼓县|万载县|宜春市|宜丰县|樟树市");
  sf[316]=new Array("宜兰县","宜兰县");
  sf[317]=new Array("益阳市","安化县|赫山区|南县|桃江县|沅江市|资阳区");
  sf[318]=new Array("银川市","贺兰县|金凤区|灵武市|西夏区|兴庆区|永宁县");
  sf[319]=new Array("鹰潭市","贵溪市|余江县|月湖区");
  sf[320]=new Array("营口市","鲅鱼圈区|大石桥市|盖州市|老边区|西市区|站前区");
  sf[321]=new Array("永州市","道县|东安县|江华瑶族自治县|江永县|蓝山县|冷水滩区|宁远县|祁阳县|双牌县|新田县|芝山区");
  sf[322]=new Array("榆林地区","定边县|府谷县|横山县|佳县|靖边县|米脂县|清涧县|神木县|绥德县|吴堡县|榆林市|子洲县");
  sf[323]=new Array("玉林市","北流市|博白县|陆川县|容县|兴业县|玉州区");
  sf[324]=new Array("玉树藏族自治州","称多县|囊谦县|曲麻莱县|玉树县|杂多县|治多县");
  sf[325]=new Array("玉溪市","澄江县|峨山彝族自治县|红塔区|华宁县|江川县|通海县|新平彝族傣族自治县|易门县|元江哈尼族彝族傣族自治县");
  sf[326]=new Array("岳阳市","华容县|君山区|临湘市|汨罗市|平江县|湘阴县|岳阳楼区|岳阳县|云溪区");
  sf[327]=new Array("运城市","河津市|稷山县|绛县|临猗县|平陆县|芮城县|万荣县|闻喜县|夏县|新绛县|永济市|垣曲县|运城市");
  sf[328]=new Array("枣庄市","山亭区|市中区|台儿庄区|滕州市|薛城区|峄城区");
  sf[329]=new Array("张家界市","慈利县|桑植县|武陵源区|永定区");
  sf[330]=new Array("张家口市","赤城县|崇礼县|沽源县|怀安县|怀来县|康保县|桥东区|桥西区|尚义县|万全县|蔚县|下花园区|宣化区|宣化县|阳原县|张北县|涿鹿县");
  sf[331]=new Array("张掖地区","高台县|临泽县|民乐县|山丹县|肃南裕固族自治县|张掖市");
  sf[332]=new Array("漳州市","东山县|华安县|龙海市|龙文区|南靖县|平和县|芗城区|云霄县|漳浦县|长泰县|诏安县");
  sf[333]=new Array("长春市","朝阳区|德惠市|二道区|九台市|宽城区|绿园区|南关区|农安县|双阳区|榆树市");
  sf[334]=new Array("长沙市","芙蓉区|开福区|浏阳市|宁乡县|天心区|望城县|雨花区|岳麓区|长沙县");
  sf[335]=new Array("长治市","城区|壶关县|郊区|黎城县|潞城市|平顺县|沁县|沁源县|屯留县|武乡县|襄垣县|长治县|长子县");
  sf[336]=new Array("昭通地区","大关县|鲁甸县|巧家县|水富县|绥江县|威信县|盐津县|彝良县|永善县|昭通市|镇雄县");
  sf[337]=new Array("镇江市","丹徒县|丹阳市|京口区|句容市|润州区|扬中市");
  sf[338]=new Array("郑州市","登封市|二七区|巩义市|管城回族区|金水区|邙山区|上街区|新密市|新郑市|荥阳市|中牟县|中原区");
  sf[339]=new Array("中卫市","海原县|沙坡头区|中宁县");
  sf[340]=new Array("舟山市","岱山县|定海区|普陀区|嵊泗县");
  sf[341]=new Array("周口市","郸城县|扶沟县|淮阳县|鹿邑县|商水县|沈丘县|太康县|西华县|项城市|周口市");
  sf[342]=new Array("株洲市","茶陵县|荷塘区|醴陵市|芦淞区|石峰区|天元区|炎陵县|攸县|株洲县");
  sf[343]=new Array("驻马店地区","泌阳县|平舆县|确山县|汝南县|上蔡县|遂平县|西平县|新蔡县|正阳县|驻马店市");
  sf[344]=new Array("资阳地区","安岳县|简阳市|乐至县|资阳市");
  sf[345]=new Array("淄博市","博山区|高青县|桓台县|临淄区|沂源县|张店区|周村区|淄川区");
  sf[346]=new Array("自贡市","大安区|富顺县|贡井区|荣县|沿滩区|自流井区");
  sf[347]=new Array("遵义市","赤水市|道真仡佬族苗族自治县|凤冈县|红花岗区|汇川区|湄潭县|仁怀市|绥阳县|桐梓县|务川仡佬族苗族自治县|习水县|余庆县|正安县|遵义县");
  sf[348]=new Array("阿坝藏族羌族自治州","阿坝县|黑水县|红原县|金川县|九寨沟县|理县|马尔康县|茂县|壤塘县|若尔盖县|松潘县|汶川县|小金县");
    var i,j,cities=new Array();
    for(i=0;i<sf.length;i++){
      if(citiec==sf[i][0].toString()){
        tmpcity=sf[i][1].split("|");
      }
    }

  return tmpcity
}