
import { defineComponent, reactive } from "vue";
import router from "@/router";
export default defineComponent({
  name: "NoPage",
  setup() {
    const rSt = reactive({
      time:6,
    });

    function countDown(){
      if (rSt.time > 0) {
        rSt.time--;
        setTimeout(function() {
            countDown();
        }, 1000)
      }
      else {
        GetReadUserData();
        rSt.time = 0;
      }
    }
    countDown();
    function GetReadUserData() {
      let tempUserRead = window.localStorage.getItem("referrer");
     router.replace(tempUserRead==null?"/":tempUserRead);
    }

    
    return {
      rSt,
    };
  },
});
