
import { defineComponent, onMounted, ref, reactive } from "vue";
import iLeftHome from "@/module/LeftHome.vue";
import { TimeyyMMdd } from "@/store/guid";
import iArticle from "@/components/home/Article.vue"; // @ is an alias to /src
import iAuthor from "@/components/home/Author.vue"; // @ is an alias to /src
import iBanner from "@/components/home/Banner.vue"; // @ is an alias to /src
import iAowokArticle from "@/components/home/AowokArticle.vue"; // @ is an alias to /src
import iQuestion from "@/components/home/Question.vue"; // @ is an alias to /src
import request from "@/api/http";
import router from "@/router";
import store from "@/store";
import { message,Carousel } from "ant-design-vue";
export default defineComponent({
  name: "Home",
  components: {
    "b-article": iArticle,
    "b-author": iAuthor,
    "b-question": iQuestion,
    "v-leftHome": iLeftHome,
    "b-banner": iBanner,
    "b-aowokArticle": iAowokArticle,
    "a-carousel":Carousel,
  },
  setup() {
  
    let articleList = ref<any>(null);
    let questionList = ref<any>(null);
    let userInfoList = ref<any>(null);
    let bannerList = ref<any>(null);
    let aowokArticleList = ref<any>(null);
    let userIn=false;
    // let userInfo: any = null;
    let userRead = reactive({
      userLogin: false,
    });

    let userInfo: any = null;
  let tempStore = store;
if (tempStore.state.userInfo!) {
  userIn=true;
}
    // //userInfo == null || userInfo.oneDayShareWeChat * 1 < parseInt(TimeyyMMdd());
    function getArticle() {
      if (tempStore.state.Article) {
        articleList = JSON.parse(tempStore.state.Article!);
      } else {
        request({
          url: "/HomeAPI/GetArticle",
        }).then((res: any) => {
          articleList.value = res.data.response;
          store.commit("saveArticle", JSON.stringify(res.data.response)); //保存 tokenAowokArticle
        });
      }
    };

    function getQuestion() {
      if (tempStore.state.Question) {
        questionList = JSON.parse(tempStore.state.Question!);
      } else {
        request({
          url: "/HomeAPI/GetQuestion",
        }).then((res: any) => {
          questionList.value = res.data.response;
          store.commit("saveQuestion", JSON.stringify(res.data.response)); //保存 token
        });
      }
    };

    function getBanner() {
      if (tempStore.state.Article) {
        bannerList = JSON.parse(tempStore.state.Banner!);
      } else {
        request({
          url: "/HomeAPI/GetBanner",
        }).then((res: any) => {
          bannerList.value = res.data.response;
          store.commit("saveBanner", JSON.stringify(res.data.response)); //保存 token
        });
      }
    };
    function getAowokArticle() {
      if (tempStore.state.AowokArticle) {
        aowokArticleList = JSON.parse(tempStore.state.AowokArticle!);
      } else {
        request({
          url: "/HomeAPI/GetAowokArticle",
        }).then((res: any) => {
          aowokArticleList.value = res.data.response;
          store.commit("saveAowokArticle", JSON.stringify(res.data.response)); //保存 token
        });
      }
    };
    getArticle();
    getQuestion();
    getBanner();
    getAowokArticle();

    function gotoArticleDetails(id: number) {
      router.push("/Ads/" + id);
    };
    function gotoQuestionDetails(id: number) {
      router.push("/Qds/" + id);
    };

    return {
      articleList,
      questionList,
      userInfoList,
      bannerList,
      aowokArticleList,
      gotoArticleDetails,
      gotoQuestionDetails,
      userIn,
      userRead
    };
  },
});
