
import { defineComponent, toRefs,reactive } from "vue";
import {
  UserOutlined, CalendarOutlined

} from '@ant-design/icons-vue';
import router from "@/router";
import store from "@/store";
// import Img from "@/views/Img.vue";
import { number } from "echarts/core";
export default defineComponent({
  name: "AowokArticle",
  components: {
    UserOutlined,
    CalendarOutlined
  },
  props: {
    key: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: "",
    },
    keyWord: {
      type: String,
      default: "",
    },
    keyWordId: {
      type: String,
      default: "",
    },
    imagesId: {
      type: String,
      default: "",
    },
    addTime: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    descriptions: {
      type: String,
      default: "",
    },
    loginName: {
      type: String,
      default: "",
    },
    HeadPortrait: {
      type: String,
      default: "",
    },
    userIn:{
      type:Boolean,
      default: "",
    }

  },
  setup(props: any) {
    let userIn =props.userIn;
    toRefs(props);
    let userRead = reactive({
      miniToken: "",
      time: "",
      id: "",
      href: "",
      title: "",
      image:"",
      rehref: "",
    });
    function goto(id: string) {
      window.location.href=store.state.api+'/a/' + id + '.html';
    }
    function gotoR(id: string,title: string,image: string) {

      userRead = JSON.parse(store.state.UserRead!);//userInfo.addTime
      userRead.title=title;
      userRead.image=image;
      userRead.rehref = window.location.pathname+window.location.search ;
      userRead.href=store.state.api+'/a/' + id + '.html';
      //userRead.href='http://localhost:50332/a/' + id + '.html';
      if (userRead.id == "0") {
        userRead.id =id;
      }
      else if (userRead.id.indexOf(id) !== -1) {
        userRead.id = userRead.id + "," + id;
        
      }
      store.commit("saveUserRead", JSON.stringify(userRead)); //保存 tokenurlId+"#"+
      // //console.log(userRead);
      // window.location.href = "http://localhost:44512/r/"+userRead.miniToken;
      // router.replace({ name: 'r', params: { id: userRead.miniToken } });//{path:’./orderList’,query:{id:2,name:’xiaoming’}
      router.push({ name: 'r', params: { id: userRead.miniToken }}) // -> /user/123
      //router.push({ name: 'q', query:{id:userRead.miniToken,name:'id'}}) // -> /user/123
      //router.push({ path: `/r/${userRead.miniToken}` }) // -> /user/123
      // window.location.open({ path: `/r/${userRead.miniToken}` }) // -> /user/123
      
    }
    return {
      gotoR,
      goto,
      userRead,
      userIn,
    }
  },
});
