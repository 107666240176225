
  import { defineComponent, onMounted, ref, reactive } from "vue";
  import iArticle from "@/components/home/Article.vue"; // @ is an alias to /src
  import iAuthor from "@/components/home/Author.vue";
  import request from "@/api/http";
  import { message } from "ant-design-vue";
  import router from "@/router";
  export default defineComponent({
    name: "ArticleListB",
    components: {
      "b-article": iArticle,
      "b-author": iAuthor,
    },
    setup() {
        let routers="1";
        const open = () => {
            let routers=router.currentRoute.value.params.id.toString();
            router.replace(routers);
        }
        open();
      return {
        routers
      };
    },
  });
  