
import { defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import request from "@/api/http";
import router from "@/router";
import { Emotions } from "@/store/purProcess";
import { timeago, DateChinaDate,timeago3Date } from '@/store/formatTime';
import { TimeChina } from "@/store/guid";
import MyInput from '@/components/input/MyInput.vue';
import iComment from "@/components/Comment/CommentList.vue";
export default defineComponent({
  name: "Qds",
  components: {
    "iComment": iComment,
    "MyInput": MyInput,
  },
  setup() {
    let id = router.currentRoute.value.params.id;
    let chatRecordList = ref<any>(null);
     //const tagList:string[]; 
     const  tagList= ref<string[]>([]);
    let questionInfo = ref<any>(null);
    let data = reactive({
  content: ''
});
    let handlePublish = (e: any) => data.content = e;


    function getQuestionById() {
      request({
        url: "/QuestionApi/Get",
        params: {
          id: id,
        },
      }).then((res: any) => {
        // chatRecordList.value = res.data.response1;
        questionInfo.value = res.data.response;
        // //console.log(questionInfo.value.tag)
        tagList.value=questionInfo.value.tag.split(',');
        // //console.log(tagList)
      });
    }

    getQuestionById();

    return { questionInfo,chatRecordList, data, timeago, DateChinaDate,handlePublish,tagList,timeago3Date, Emotions };
  },
});
