import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e3e57c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "hbbox"
}
const _hoisted_2 = { class: "hbtitle" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "hbupTime"
}
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_outlined = _resolveComponent("calendar-outlined")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle({ backgroundImage: 'url(https://www.aowok.com/' + _ctx.image + ')' }),
      class: "banner"
    }, [
      (_ctx.title != null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              (_ctx.title != null)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: 'https://www.aowok.com/' + _ctx.url + '.html'
                  }, _toDisplayString(_ctx.title), 9, _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            (_ctx.title != null)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                  _createVNode(_component_calendar_outlined, { style: {"text-shadow":"1px 1px 1px black"} }),
                  _createTextVNode(" " + _toDisplayString(_ctx.upTime.substring(0, 10)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.title != null)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: "hbdescriptions",
                  title: _ctx.descriptions
                }, _toDisplayString(_ctx.descriptions), 9, _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}