
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message,Progress,RadioButton,RadioGroup,MenuItem,Menu,Dropdown,Checkbox,Radio } from "ant-design-vue";
import { DateMinute,SecondToTime } from "@/store/formatTime";
import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";
import { TopicClass2,TopicClass3 } from "@/store/watermark";
import { LevelLine} from "@/store/purProcess";
import iGuidelines from "@/components/system/Guidelines.vue";
import psyMemo from "@/components/Psychotheraphy/PsyMemo.vue";
import { SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined ,RollbackOutlined,LoadingOutlined} from '@ant-design/icons-vue';
import { number } from "echarts/core";
interface QusAns {
  qus: string;
ans: string;
ans2: string;
}
interface TopicsDto {
  id: string;
  orders: string;
  mark1: string;
  qus: string;
  levels: number;
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  fenTrue:number;
  fenFalse:number;
  ans:string;
  parses:string;
  class1:string;
  class2:string;
  class3:string;
}
interface TopicsCount {
  all:number;
  ture:number;

}
export default defineComponent({
  name: "Psychotheraphy",
  components: {
    Vcode,
    "v-psyMemo":psyMemo,
        "a-progress": Progress,
    "a-button": RadioButton,
    "a-radio-group": RadioGroup,
      "a-menu":Menu,
       "a-menu-item":MenuItem,
       "a-dropdown":Dropdown,
       "a-checkbox":Checkbox,
       "a-radio":Radio,
    "iGuidelines":iGuidelines,
    SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined,RollbackOutlined,LoadingOutlined
  },
  setup() {
const topicSendDto = reactive({class1:"心理治疗师多选",class2:"全部",class3:"全部",class4:"正序",class5:"打乱选项",ans:"",id:"",pagekey:0});
const topicsDto = ref<TopicsDto>();
let qusAns = ref<QusAns[]>([]);
const topicsNewsDto3 = ref<TopicsDto>();
const topicsNewsDto2 = ref<any>();
  const state = reactive({
      fenTrue: 0,
      fenFalse: 0,
      countLong: 0,
      ans:"",
      a:false,
      b:false,
      c:false,
      d:false,
      e:false,
      f:false,
      nextButton: false,
      count:"",
    });
const handleClass2= () => {
      topicSendDto.class2="全部";
}
const handleClass3= () => {
      topicSendDto.class3="全部";
}
const buttonOnS=() =>{
    state.nextButton=true;
  qusAns.value.push({
      qus: topicsDto.value?.qus==undefined?"":topicsDto.value?.qus,
      ans: topicsDto.value?.ans==undefined?"":topicsDto.value?.ans,
      ans2:topicsDto.value?.ans==undefined?"":topicsDto.value?.ans,
  });
if(topicsDto.value?.ans!=undefined)
{
var anss=topicsDto.value.ans.split(",");
var ancc=[];
var turs=0;
      if(state.a==true)
      {
        ancc.push(topicsDto.value.a)
      }
      if(state.b==true)
      {
        ancc.push(topicsDto.value.b)
      }
      if(state.c==true)
      {
        ancc.push(topicsDto.value.c)
      }
      if(state.d==true)
      {
        ancc.push(topicsDto.value.d)
      }
      if(state.e==true)
      {
        ancc.push(topicsDto.value.e)
      }
      if(state.f==true)
      {
        ancc.push(topicsDto.value.f)
      }
      for(let iss = 0; iss<anss.length; iss++)
    {
      for(let i = 0; i<ancc.length; i++)
    {
      if(ancc[i]==anss[iss]){
        turs++;
      }
    }

    if(turs==anss.length&&anss.length==ancc.length)
    {
      state.ans="对";
    }
    else{
      state.ans="错";
    }
    }
    if(state.ans=="对")
    {
        state.fenTrue++;
  topicSendDto.ans="对";
  
  state.nextButton=true;
    }
    else{
      state.fenFalse++;
  topicSendDto.ans="错";
    }
}

}
const buttonNext=() =>{

  if(topicsDto.value!=null){
topicSendDto.id=topicsDto.value.id;
  }
onS();
}
const buttonCount=() =>{
  request({
        url: "/MentonApi/PsychotheraphycCount",
        method: "get",
      }).then((res: any) => {
        if (res.data.success) {
          state.count=res.data.response;
        }
      });
}
    const onS=() =>{
      //console.log(topicSendDto)
      request({
        url: "/MentonApi/Psychotheraphy",
        method: "post",
        data: topicSendDto
      }).then((res: any) => {
        if (res.data.success) {
          topicsDto.value=res.data.response;
          //console.log(topicsDto.value)
            state.nextButton=false;
          topicSendDto.id=res.data.response.id;
          // state.ans.length=0;
          topicSendDto.ans="";
              state.a=false;
    state.b=false;
    state.c=false;
    state.d=false;
    state.e=false;
    state.f=false;
    state.ans="";
        }
        else{
          message.error("获取信息失败");
        }
      });
    }
onS();
    return {
      DateMinute,qusAns,
     buttonCount,
      labelCol: { style: { width: "80px" } },
      wrapperCol: { span: 14 },state,handleClass3,handleClass2,onS,TopicClass2,TopicClass3,
      SecondToTime,buttonOnS,buttonNext,topicSendDto,topicsNewsDto2,topicsNewsDto3,topicsDto,LevelLine
    };
  },
});
