
import { Emotions, YiShiIdName } from "@/store/purProcess";
import { reactive, onMounted, ref, createVNode } from "vue";
import { timeago, DateChinaLong } from '@/store/formatTime';
import { TimeChina,guid16 } from "@/store/guid";
import { Modal, message, notification } from "ant-design-vue";
import * as signalR from "@microsoft/signalr";
import Emotion from "@/components/input/EmotionIndex.vue";
import router from "@/router";
import store from "@/store";
import request from "@/api/http";
import { ScanOutlined, DisconnectOutlined, ShakeOutlined, BarsOutlined, DeleteOutlined, HomeOutlined, SmileOutlined, PictureOutlined, SendOutlined, FullscreenExitOutlined, FullscreenOutlined, MailOutlined, IssuesCloseOutlined, CloseCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined, EditOutlined, PaperClipOutlined, ExclamationCircleOutlined, CustomerServiceOutlined, CommentOutlined } from '@ant-design/icons-vue';//issues-close-outlined /><close-circle-outlined
import { any } from "vue-types";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
interface response {
  id: string; boxColor: string; createTime: string; sendMsg: string; userName: string; HeadPortrait: string; quote: string; ip: string; ipCity: string; userEmail: string
}
interface roomList {
  id: string; ip: string; ipCity: string; name: string; msg: string; sendmsg: string; news: boolean; response1: response[]; response2: response
}

interface JoinGroupDto {
  groupId: string;
  email: string;
  token: string;
  ip: string;
  ipCity: string;
  classs: string;
}
interface SendGroupMsgDto {
  id: string;
  groupId: string;
  msg: string;
  quote: string;
  userId: string;
  userName: string;
  userEmail: string;
  HeadPortrait: string;
  classs: string;
  createTime: string;
  ip: string;
  ipCity: string;
}
interface ReadMsgData {
  chatRoomName: string;
  response1: response[];
  response2: response;
}
let connection: any;
//图片上传
interface Result {
  dataURL: string;
  blobURL: string;
}
export default {
  name: "QCRTEA",
  components: {
    DisconnectOutlined, VuePictureCropper, FullscreenExitOutlined, FullscreenOutlined, SendOutlined, ScanOutlined, BarsOutlined, DeleteOutlined, HomeOutlined,
    MailOutlined, SmileOutlined, ShakeOutlined, PictureOutlined, IssuesCloseOutlined, CloseCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined, EditOutlined, ExclamationCircleOutlined, PaperClipOutlined, CustomerServiceOutlined, CommentOutlined,
    Emotion,
  },
  setup() {
    let userInfo: any = null;
    let tempStore = store;
    if (tempStore.state.userInfo) {
      userInfo = JSON.parse(tempStore.state.userInfo!);
    }

    //引用打开
    const handleQuote = (sendMsg: string) => {
      state.quote = sendMsg;
    }
    //清理引用数据
    const handleCloseQuoTe = () => {
      state.quote = "";
    }

    //设群名
    const handleSaveGroupName = (sendMsg: string) => {
      let msgContent = ""
      if (sendMsg.length > 20) {
        message.error("由于群名限制为20个字符以内,不支持提交", 3);
        return;
      }
      else {
        msgContent = '是否设置【' + sendMsg + '】为本群名称?'
      }
      Modal.confirm({
        title: '设置本群名称',
        icon: createVNode(ExclamationCircleOutlined),
        content: msgContent,
        okText: '设置',
        cancelText: '取消',
        onOk() {
          const sendGroupMsgDto = reactive<SendGroupMsgDto>({
            id: "",
            groupId: state.groupId,//1
            msg: sendMsg,//2
            quote: "",//5
            userId: userInfo.id,
            userEmail: tempStore.state.token!,
            userName: "TEA",
            classs: "GroupName",//3
            createTime: "",//4
            ip: state.ip,
            ipCity: state.ipCity,
            HeadPortrait: "",
          });
          SendMsg(sendGroupMsgDto);
        },
        onCancel() { },
      });
    }
    //撤回消息
    const handleRecall = (responseNumber: number, sendMsg: string, createTime: string) => {
      Modal.confirm({
        title: '撤回消息(1分钟内有效)',
        icon: createVNode(ExclamationCircleOutlined),
        content: '撤回' + timeago(createTime) + '发布的【' + sendMsg + '】，撤回时，各客户端同时撤回',
        okText: '提交',
        cancelText: '取消',
        onOk() {
          const sendGroupMsgDto = reactive<SendGroupMsgDto>({
            id: "",
            groupId: state.groupId,//1
            msg: sendMsg,//2
            quote: "",//5
            userId: "",
            userEmail: tempStore.state.token!,
            userName: "TEA",
            classs: "Recall",//3
            createTime: createTime,//4
            ip: state.ip,
            ipCity: state.ipCity,
            HeadPortrait: "",
          });
          SendMsg(sendGroupMsgDto);

        },
        onCancel() { },
      });
    }
    //修改（加密）数据
    const handleEdit = (index: number, sendMsg: string) => {
      // state.edit[index]=true;
      state.editMsg = sendMsg;
      // //console.log(sendMsg.toString());
    }
    //修改（加密）数据
    const handleEditSave = (id: string, sendMsg: string, sendMsgOld: string) => {
      const sendGroupMsgDto = reactive<SendGroupMsgDto>({
        id: id,
        groupId: state.groupId,//1
        msg: sendMsg,//2
        quote: sendMsgOld,//5
        userId: "",
        userEmail: tempStore.state.token!,
        userName: "TEA",
        classs: "Edit",//3
        createTime: "",//4
        ip: state.ip,
        ipCity: state.ipCity,
        HeadPortrait: "",
      });
      SendMsg(sendGroupMsgDto);
      // SendPublic(state.groupId, sendMsg,"Edit",createTime,sendMsgOld);
    }
    //删除数据
    const handleDelete = (id: string, sendMsg: string, createTime: string) => {
      Modal.confirm({
        title: '删除消息',
        icon: createVNode(ExclamationCircleOutlined),
        content: '删除发布的【' + sendMsg + '】，删除后将无法恢复！请确认',
        okText: '提交',
        cancelText: '取消',
        onOk() {
          const sendGroupMsgDto = reactive<SendGroupMsgDto>({
            id: id,
            groupId: state.groupId,//1
            msg: sendMsg,//2
            quote: "",//5
            userId: "",
            userEmail: tempStore.state.token!,
            userName: "TEA",
            classs: "Delete",//3
            createTime: "",//4
            ip: state.ip,
            ipCity: state.ipCity,
            HeadPortrait: "",
          });
          SendMsg(sendGroupMsgDto);
          // SendPublic(state.groupId, sendMsg,,createTime,"");
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() { },
      });
    }
    //删除数据
    const handleDeletePic = (id: string, sendMsg: string, createTime: string) => {
      Modal.confirm({
        title: '删除消息',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确认删除图片？',
        okText: '提交',
        cancelText: '取消',
        onOk() {
          const sendGroupMsgDto = reactive<SendGroupMsgDto>({
            id: id,
            groupId: state.groupId,//1
            msg: "",//2
            quote: "",//5
            userId: "",
            userEmail: tempStore.state.token!,
            userName: "TEA",
            classs: "Delete",//3
            createTime: createTime,//4
            ip: state.ip,
            ipCity: state.ipCity,
            HeadPortrait: "",
          });
          SendMsg(sendGroupMsgDto);
          // SendPublic(state.groupId, sendMsg,,createTime,"");
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() { },
      });
    }//发送邮件
    const handleSendMail = (index: number) => {
      const sendGroupMsgDto = reactive<SendGroupMsgDto>({
        id: "",
        groupId: state.groupId,//1
        msg: state.sendMailQ[index] + "【分裂】" + state.sendMailA,//2
        quote: state.sendMailAccount[index],//5
        userId: "",
        userEmail: tempStore.state.token!,
        userName: "TEA",
        classs: "SendMail",//3
        createTime: "",//4
        ip: state.ip,
        ipCity: state.ipCity,
        HeadPortrait: "",
      });
      SendMsg(sendGroupMsgDto);
      // SendPublic(state.groupId, state.sendMailQ[index]+"【分裂】"+state.sendMailA,"SendMail","",state.sendMailAccount[index]);
    }//向学员发送邮件
    const handleSendMailOpen = (index: number, sendMsg: string) => {
      state.sendMailA = "";
      state.sendMailQ.length = 0;
      state.sendMailAccount.length = 0;
      state.sendMailRadioQ = 0;
      state.sendMailA = sendMsg;
      // var number=articleData.value.findIndex(articleDatac => articleDatac.sendMsg===sendMsg)//查找点击前的ID位置
      // let Qus="";
      for (let i = index; i > 0; i--) {
        // //console.log('匹配倒序：'+i);
        if (articleData.value.response1[i - 1].boxColor == "otherbox" && articleData.value.response1[i - 1].quote != '【**图片**】')//otherbox mebox
        {
          state.sendMailQ.push(articleData.value.response1[i - 1].sendMsg)
          state.sendMailAccount.push(articleData.value.response1[i - 1].userEmail)
        }
      }
      if (state.sendMailQ.length != 0) {
        state.sendMailOpen = true;
      }
      else {
        message.error("无法找到可匹配到的问题！请重试！");
      }
    }
    //发送AI记录
    const handleSaveAI = (index: number) => {
      const sendGroupMsgDto = reactive<SendGroupMsgDto>({
        id: "",
        groupId: state.groupId,//1
        msg: state.sendMailQ[index] + "【分裂】" + state.sendMailA,//2
        quote: "",//5
        userId: "",
        userEmail: tempStore.state.token!,
        userName: "TEA",
        classs: "SaveAI",//3
        createTime: "",//4
        ip: state.ip,
        ipCity: state.ipCity,
        HeadPortrait: "",
      });
      SendMsg(sendGroupMsgDto);
      // SendPublic(state.groupId,,,"","");
    }
    //AI记录-打开
    const handleSaveAIOpen = (index: number, sendMsg: string) => {
      // //console.log(sendMsg);
      state.sendMailA = "";
      state.sendMailQ.length = 0;
      state.sendMailRadioQ = 0;
      state.sendMailA = sendMsg;
      // var number=articleData.value.findIndex(articleDatac => articleDatac.sendMsg===sendMsg)//查找点击前的ID位置
      // let Qus="";
      for (let i = index; i > 0; i--) {
        // //console.log('匹配倒序：'+i);
        if (articleData.value.response1[i - 1].boxColor == "otherbox" && articleData.value.response1[i - 1].quote != '【**图片**】')//otherbox mebox
        {
          state.sendMailQ.push(articleData.value.response1[i - 1].sendMsg)
          // state.sendMailAccount.push(articleData.value.response1[i-1].userEmail)
        }
      }
      if (state.sendMailQ.length != 0) {
        state.sendAiOpen = true;
      }
      else {
        message.error("无法找到可匹配到的问题！请重试！");
      }
    }

    const roomList = ref<roomList[]>([]);

    const listClass = ref<boolean>(true);
    let inputroom = ref<string>("0");
    let tcmsg = ref<string>();
    const articleData = ref<any>();
    let articleNowMsgs = ref<any>();//handleWindowClear,handleGoHome,
    let guestId: any = JSON.parse(window.localStorage.getItem('GuestId')!);
    const handleWindowClear = () => {
      message.success("缓存清理完成，并退出账户");
      window.localStorage.clear();
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
    const handleGoHome = () => {
      router.replace("/PurHome");
    }
    const ListClassClick = () => {
      listClass.value = listClass.value == true ? false : true;
    }
    //切换列表
    const handleChange = (id: string) => {

      if(state.groupId!="")
      {
        var number = roomList.value.findIndex(roomList => roomList.id === state.groupId)//查找点击前的ID位置
      roomList.value[number].sendmsg = state.msg==null?"":state.msg;//把输入中的内容存入sendmsg
      articleData.value = null;//清理列表历史聊天记录
      roomList.value[number].news = false;
      // state.groupName = roomList.value[number].name;
      }
      //console.log(id+"/123")
      let item = roomList.value.find(i => i.id == id);//查找点击后的ID数据
      //console.log(item)
      articleData.value = item;//写入数据中
      //console.log(articleData.value)
      state.groupName =item!.name;
      //console.log(item!.name)
      state.msg = item?.sendmsg!;//把要发送的资料放到输入框中
      //console.log(item?.sendmsg!)
      state.quote = "";
      state.dictUser =false;
        const joinGroupDto = reactive<JoinGroupDto>({
        token: "",
        groupId: id,
        email: state.groupId,
        ip: state.ip,
        ipCity: state.ipCity,
        classs: "tea",
      });
      UserState(joinGroupDto);
      state.groupId = id;//更新所在ID列表位置
      classs.value=id.substring(0,3);
      if (item?.name != null) {
        state.groupName = item?.name;
      }
      setTimeout(function () {
            let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];
            if (chatScrollTop.scrollHeight > chatScrollTop.clientHeight) {
              //设置滚动条到最底部
              chatScrollTop.scrollTop = chatScrollTop.scrollHeight;
            }
          }, 300)
      // setTimeout(function () {
      //   //设置滚动条到最底部
      //   let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];//获取高度
      //   chatScrollTop.scrollTop = chatScrollTop.scrollHeight;
      // }, 200);
    };
    let chatQuestionsList = ref<any>(null);



    //获取聊天室历史数据
    function setLocalStorage(id: string, data: any) {
      window.localStorage.setItem(id, JSON.stringify(data));//chatQuestionsList.value
    };
    //关闭room
    const handleClose = (id: string) => {
      state.openDelete = true;
      state.DeleteId = id;
      handleChange(roomList.value[0].id);
      roomList.value = roomList.value.filter(roomList => roomList.id !== state.DeleteId);
    };
    //清理本室下次不加载
    const handleCloseClass = (classs: string) => {
      //console.log(state.DeleteId + "/handleCloseA");
      const joinGroupDto = reactive<JoinGroupDto>({
        groupId: state.DeleteId,
        token: tempStore.state.token!,
        email: "",
        ip: "",
        ipCity: "",
        classs: classs,
      });
      TeaDeleteRoom(joinGroupDto);
      state.openDelete = false;
    };

    function TeaDeleteRoom(joinGroupDto: JoinGroupDto) {
      connection
        .invoke("TeaDeleteRoom", joinGroupDto)
        .then((res: any) => {
          if (res.success != true) {
            message.error(res.msg);
          }
          else {
            message.info(res.msg);
          }
        })
        .catch(function (err: any) {
          message.info("删除失败！");
        });
    }

    //检查学员在线情况
    function countDown() {
      //console.log(state.teaDelayComeTime)
      if (state.teaDelayComeTime > 1) {
        state.teaDelayComeTime--;
        setTimeout(function () {
          countDown();
        }, 1000)
      }
      else {
        // state.groupId.substring(0,3).toLowerCase()=='fre'
        if (userInfo.id != state.groupId) {
          const joinGroupDto = reactive<JoinGroupDto>({
            token: "",
            groupId: state.groupId,
            email: "",
            ip: state.ip,
            ipCity: state.ipCity,
            classs: "tea",
          });
          UserState(joinGroupDto)
        }

        state.teaDelayComeTime = 60;//20秒检测一次服务师是否在线处理你的事情
        countDown();
      }
    };

    const UserState = (joinGroupDto: JoinGroupDto) => {
      connection
        .invoke("UserState", joinGroupDto)
        .catch(function (err: any) {
          //console.log(err);
          message.warning("传参异常..");
        });
    }

    const isShow = ref(false);
    const onShow = () => {
      isShow.value = true;
    };
    let data = reactive({
      content: "",
      isShowEmotion: false,
    });

    const handlePublish = () => {
      data.isShowEmotion = false;
      if (state.msg.length > 0) {
        const sendGroupMsgDto = reactive<SendGroupMsgDto>({
          id: "",
          groupId: state.groupId,//1
          msg: state.msg,//2
          quote: state.quote,//5
          userId: "",
          userEmail: tempStore.state.token!,
          userName: "TEA",
          classs: "Send",//3
          createTime: "",//4
          ip: state.ip,
          ipCity: state.ipCity,
          HeadPortrait: "",
        });
        SendMsg(sendGroupMsgDto);
        // SendPublic(state.groupId, state.msg,"Send","",state.quote);
      }
      else {
        message.warning("请输入内容");
      }
    }
    const state = reactive({
      msg: "",
      userId: "",
      userName: "",
      quote: "",
      groupId: "",
      groupName: "",
      teaList: [] as string[],
      ip: "",
      ipCity: "",
      // edit:[] as Boolean[],
      editMsg: "",
      sendMailQ: [] as string[],
      sendMailAccount: [] as string[],
      sendMailA: "",
      sendMailOpen: false,
      sendAiOpen: false,
      sendMailRadioQ: 0,
      isPicShowDialog: false,
      pic: "",
      id: "TEA",
      openQR: false,
      openDelete: false,
      DeleteId: "",
      location: window.location.href,
      teaDelayComeTime:0,
      dictUser: false,
    });
    const classs = ref("");
    const isShows = ref("");
    //图片上传ON
    const uploadInput = ref<HTMLInputElement | null>(null);
    const pic = ref<string>("");
    const result: Result = reactive({
      dataURL: "",
      blobURL: "",
    });
    const isShowDialog = ref<boolean>(false);
    /**
     * 选择图片
     */
    const selectFile = (e: Event): void => {
      // 重置上一次的结果
      result.dataURL = "";
      result.blobURL = "";
      // 如果有多个裁剪框，也需要重置掉裁剪目标的值，避免使用同一张图片无法触发watch
      pic.value = "";
      // 获取选取的文件
      const { files } = e.target as HTMLInputElement;
      if (!files) return;
      const file: File = files[0];
      // 转换为base64传给裁切组件
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      uploadInput.value = null;
      reader.onload = (): void => {
        // 更新裁切弹窗的图片源
        pic.value = String(reader.result);
        // 显示裁切弹窗
        isShowDialog.value = true;
        // 清空已选择的文件
        if (!uploadInput.value) return;
        uploadInput.value = null;
      };
    };
    /**
     * 获取裁切结果
     */
    const getResult = async (): Promise<void> => {
      // 获取生成的base64图片地址
      const base64: string = cropper.getDataURL();
      result.dataURL = base64;
      uploadInput.value = null;
      try {
        uploaderHeadPortrait(base64)

      } catch (e) {
        result.blobURL = "";
      }
      // 隐藏裁切弹窗
      isShowDialog.value = false;
    };
    /**
     * 清除裁切框
     */
    const clear = (): void => {
      cropper.clear();
    };
    /**
     * 重置默认的裁切区域
     */
    const reset = (): void => {
      cropper.crop();
      cropper.reset();
    };
    const uploaderHeadPortrait = (base64: string) => {
        var a = 3500; //循环按3000
        var length=base64.length;
        // //console.log(length);
        var b = Math.ceil(length/ a); //向上取整；
        // //console.log(length+"/"+b+"/"+a);
        const sendGroupMsgDto = reactive<SendGroupMsgDto>({
          id:"",
          groupId: state.groupId, //1
          msg: "", //2
          quote: guid16(), //5
          userId:userInfo.id,
          userEmail:userInfo.email,
          userName:userInfo.UserName,
          classs: "SenPic", //3
          createTime: "", //4
          ip: state.ip,
          ipCity: state.ipCity,
          HeadPortrait: b.toString(),
        });
        for (let i = 0; i < b;  i++) {
          
          sendGroupMsgDto.userName = (i+1).toString();
          // //console.log(sendGroupMsgDto.userName);
          if(length>a)
          {
            sendGroupMsgDto.msg=base64.substring(0,a);
            // //console.log(sendGroupMsgDto.msg.substring(0,5));
            // length=length-a;
            //console.log(length);
            base64=base64.substring(a, base64.length);
            //console.log(base64.substring(0,10));
          }
          else{
            sendGroupMsgDto.msg=base64;
            // //console.log(sendGroupMsgDto.msg.substring(0,5));
          }
          // //console.log(length);
          connection
            .invoke("SendMsg", sendGroupMsgDto)
            .then((res: any) => {
              // //console.log("进度["+res.response+"]");
              if (res.success == false) {
                i == 100000;
                //console.log("返回失败！");
              }
             
            })
            .catch(function (err: any) {
              // //console.log(err.toString()+"9999");
              i = 100000;
            });
          if (i == 100000) {
            message.error("图片发送失败~");
            break;
          }
        }
    };
    //图片上传END
    //向特定群发送数据
    function SendPublic(id: string, msg: string, classs: string, createTime: string, quote: string) {
      createTime == "" ? (new Date().toString()) : createTime;

      data.isShowEmotion = false;
      request({
        url: "/ChatApi/SendGroupMsg",
        method: "post",
        data: {
          groupId: id,//1
          msg: msg,//2
          quote: quote,//5
          userId: "",
          userEmail: tempStore.state.token!,
          userName: "",
          classs: classs,//3
          createTime: createTime,//4
          ip: state.ip,
          ipCity: state.ipCity,
          HeadPortrait: "",
        }
      }).then((res: any) => {
        if (res.data.success) {
          if (classs == "Send") {
            state.msg = "";
            var number = roomList.value.findIndex(roomList => roomList.id === state.groupId)//查找点击前的ID位置
            roomList.value[number].sendmsg = "";//把输入中的内容存入sendmsg
            // //console.log("新增state.msgs數據"+  JSON.stringify(state.msgs));
          }
          else if (classs == "SendMail" || classs == "SaveAI") {
            state.sendMailA = "";
            state.sendMailQ.length = 0;
            state.sendMailAccount.length = 0;
            state.sendMailRadioQ = 0;
            state.sendMailOpen = false;
            state.sendAiOpen = false;
            message.success(res.data.msg);
          }
          else {
            message.success(res.data.msg);
          }
        } else {
          message.error(res.data.msg);
        }
      });
    }
    // function CallTEACome(groupId: string, userName: string, ip: string, ipCity: string) {
    //   request({
    //     url: "/ChatApi/CallTEACome",
    //     method: "get",
    //     data:  {
    //       groupId: groupId,
    //       userName: userName,
    //       ip: ip,
    //       ipCity: ipCity,
    //     },
    //   }).then((res: any) => {
    //     if (res.data.success) {
    //       // message.success(TimeChina() +"",5)
    //       message.success(TimeChina() + res.data.msg, 5);
    //     } else {
    //       message.error(res.data.msg);
    //     }
    //   });
    // }
    function JoinChat(joinGroupDto: JoinGroupDto) {
      connection
        .invoke("JoinChat", joinGroupDto)
        .catch(function (err: any) {
          message.info("账号在其他地方登录，或登录超时，请重新登录");
        });
    }


    function GetFreUserMsg(joinGroupDto: JoinGroupDto) {
      connection
        .invoke("GetFreUserMsg", joinGroupDto)
        .then((res: any) => {
          //console.log(res)
          if (res.success == true && joinGroupDto.groupId == res.msg) {
            var number = roomList.value.findIndex(roomList => roomList.id == res.response.id);
            //console.log(number+"GetFreUserMsg")
            roomList.value[number].response1 = res.response.response1;
            roomList.value[number].ip= res.response.ip;
            roomList.value[number].ipCity= res.response.ipCity;
            //console.log(roomList.value[number]);
          }
          else {
            message.error(res.msg);
          }
        })
        .catch(function (res: any) {
          message.error("获取" + joinGroupDto.groupId + "留言失败");
        });
    }
    function SendMsg(sendGroupMsgDto: SendGroupMsgDto) {
      connection
        .invoke("SendMsg", sendGroupMsgDto)
        .catch(function (res: any) {
          message.error("发送<" + sendGroupMsgDto.msg + ">失败！");
        });
    }
    const openNotification = (message: string, description: string, id: string, duration: number, classs: string) => {
      notification.open({
        message: message,
        description: description,
        style: {
          width: '250px',
          marginLeft: `${5 - 10}px`,
        },
        class: 'notification-custom-class',
        duration: duration,//延时关闭
        onClick: () => {
          if (classs == "TEA") {

          }
          handleChange(id);
        },
      });
    };
    onMounted(async function () {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(store.state.api + "/chatHub")//https://www.aowok.com
        .withAutomaticReconnect()
        .build();
      await connection.start(
        setTimeout(function () {
          state.groupId=userInfo.id;
          if (roomList.value.findIndex(roomList => roomList.name === userInfo.id) == -1)//查找是否有相同的ID
          {
            const joinGroupDto = reactive<JoinGroupDto>({
              groupId: userInfo.id,
              token: tempStore.state.token!,
              email: userInfo.email,
              ip: guestId.comment,
              ipCity: "",
              classs: "tea",
            });
            JoinChat(joinGroupDto);
            // getChatRecord(userInfo.id,"");
            countDown();
          }
        }, 2000)
      );
      connection.on("ReceiveGroup", (SendGroupMsgDto: SendGroupMsgDto) => {
        if (SendGroupMsgDto.classs == "Send" || SendGroupMsgDto.classs == "SenPic") {
          var number = roomList.value.findIndex(roomList => roomList.id == SendGroupMsgDto.groupId)//查找点击前的ID位置
          // //console.log(number+"【】"+SendGroupMsgDto.groupId+"【】"+SendGroupMsgDto.quote+SendGroupMsgDto.classs+SendGroupMsgDto.msg+SendGroupMsgDto.userEmail)
          roomList.value[number].response1.push({
            id: SendGroupMsgDto.id,
            boxColor: userInfo.email == SendGroupMsgDto.userEmail ? 'mebox' : 'otherbox',
            createTime: SendGroupMsgDto.createTime,
            sendMsg: SendGroupMsgDto.msg,
            userName: SendGroupMsgDto.userName,
            userEmail: SendGroupMsgDto.userEmail,
            HeadPortrait: SendGroupMsgDto.HeadPortrait,
            quote: SendGroupMsgDto.quote,
            ip: SendGroupMsgDto.ip,
            ipCity: SendGroupMsgDto.ipCity,
          });

          // //console.log("2"+groupId+name+a[0]+a[1])
          // var number=roomList.value.findIndex(roomList => roomList.id===state.groupId)//查找点击前的ID位置
          roomList.value[number].sendmsg = "";//清除準備輸入的内容
          roomList.value[number].news = SendGroupMsgDto.groupId != state.groupId ? true : false;//清除準備輸入的内容groupId!=state.groupId?true:false
          setTimeout(function () {
            let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];
            if (chatScrollTop.scrollHeight > chatScrollTop.clientHeight) {
              //设置滚动条到最底部
              chatScrollTop.scrollTop = chatScrollTop.scrollHeight;
            }
          }, 300)
          // //console.log(state.groupId.toString());
          // //console.log(groupId);
          if (state.groupId == SendGroupMsgDto.groupId || SendGroupMsgDto.groupId == state.groupId) {
            state.msg = "";
            state.quote = "";
          }
          else {
            if(SendGroupMsgDto.classs == "SenPic")
            {
            openNotification(SendGroupMsgDto.userName + ":", "发来了图片", SendGroupMsgDto.groupId, 5, "");
            }
            else{
            openNotification(SendGroupMsgDto.userName + ":", SendGroupMsgDto.msg, SendGroupMsgDto.groupId, 5, "");

            }
          }
        }
        else if (SendGroupMsgDto.classs == "Recall" || SendGroupMsgDto.classs == "Delete") {

          var number = roomList.value.findIndex(roomList => roomList.id === SendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.id === SendGroupMsgDto.id)//查找点击前的ID位置
          roomList.value[number].response1[responseNumber].boxColor = "nullbox";
          roomList.value[number].response1[responseNumber].sendMsg = SendGroupMsgDto.classs == "Recall" ? "---撤回了一条消息---" : "---该信息已被删除---";
          if (SendGroupMsgDto.groupId == state.groupId) {
            // message.success(msg+classs+groupId);
            articleData.value[responseNumber].boxColor = "nullbox";
            articleData.value[responseNumber].sendMsg = SendGroupMsgDto.classs == "Recall" ? "---撤回了一条消息---" : "---该信息已被删除---";
          }
          if (SendGroupMsgDto.groupId != state.groupId) {
            openNotification(name + ":", '---撤回了一条消息---', SendGroupMsgDto.groupId, 5, "");
          }
        }
        else if (SendGroupMsgDto.classs == "Edit") {
          var number = roomList.value.findIndex(roomList => roomList.id === SendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.sendMsg === SendGroupMsgDto.quote)//查找点击前的ID位置
          roomList.value[number].response1[responseNumber].sendMsg = SendGroupMsgDto.msg;
          roomList.value[number].response1[responseNumber].quote = articleData.value[responseNumber].quote + "(平台对该信息进行加密处理)";
          message.success(roomList.value[number].response1[responseNumber].sendMsg)
          if (SendGroupMsgDto.groupId == state.groupId) {
            articleData.value[responseNumber].sendMsg = SendGroupMsgDto.msg;
            articleData.value[responseNumber].quote = articleData.value[responseNumber].quote + "(平台对该信息进行加密处理)";
            message.success(articleData.value[responseNumber].sendMsg)
          }
        }

        else if (SendGroupMsgDto.classs == "GroupName") {
          var number = roomList.value.findIndex(roomList => roomList.id === SendGroupMsgDto.groupId)//查找点击前的ID位置
          var responseNumber = roomList.value[number].response1.findIndex(response => response.sendMsg === SendGroupMsgDto.msg)//查找点击前的ID位置
          roomList.value[number].name = SendGroupMsgDto.msg;
        }
      });
      // connection.on("ReceiveCaller", (msg: never) => {
      //   state.msgs.push(msg);
      // });
      connection.on("ReceiveJoinGroup", (joinGroupDto: JoinGroupDto) => {
        state.ip = joinGroupDto.ip;
        state.ipCity = joinGroupDto.ipCity;
        setTimeout(() => {
          if (joinGroupDto.email != userInfo.email) {
            message.success("用戶：(" + joinGroupDto.email + ")" + joinGroupDto.token + "進入聊天室", 5);
          }
        }, 5000);
      });


      connection.on("ReadMsgData", (roomListNow: roomList) => {
        //console.log(roomListNow)
        var number = roomList.value.findIndex(roomList => roomList.id == roomListNow.id)//查找点击前的ID位置
        //console.log(number + "number")
        if (number == -1) {
          if (roomListNow.name == "公开咨询室") {
            roomListNow.name = YiShiIdName(roomListNow.id.substring(17, 19)) + YiShiIdName(roomListNow.id.substring(19, 21)) + YiShiIdName(roomListNow.id.substring(21, 23)) + "咨询室"
            // roomList.value.push(roomListNow);
            // state.groupId = roomListNow.id;
          }
          else if (roomListNow.id == userInfo.id) {
            roomListNow.name = " 导师日志 ";
          }
          var a=roomListNow.response1.length;
          for(var i=0 ;i<a;i++)
          {
            roomListNow.response1[i].boxColor=userInfo.email==roomListNow.response1[i].userEmail?"mebox" : "otherbox";
          }
          roomList.value.push(roomListNow);
          //console.log(roomList.value.length+'/12')
          if(roomList.value.length==1)
          {
            state.groupId=roomList.value[0].id;
            articleData.value = roomList.value[0];//写入数据中
            //console.log('articleData')
            let chatScrollTop = document.getElementsByClassName('chatScrollTop')[0];
            if (chatScrollTop.scrollHeight > chatScrollTop.clientHeight) {
              chatScrollTop.scrollTop = chatScrollTop.scrollHeight;//设置滚动条到最底部
            }
          }
          handleChange(roomList.value[0].id)
          //console.log(roomList)
        }
      });
      connection.on("RecChatUserState", (joinGroupDto: JoinGroupDto) => {
        if (joinGroupDto.groupId == state.groupId) {
          state.dictUser = true;
        }
      });
      connection.on("RecChatTeaState", (joinGroupDto: JoinGroupDto) => {
        if(joinGroupDto.token=="给你发来震动~")
          {
            openNotification("公开咨询者(" + joinGroupDto.ipCity + "):", joinGroupDto.token, joinGroupDto.groupId, 5, "TEA");
          }
        setTimeout(() => {
          var number = roomList.value.findIndex(roomList => roomList.id === joinGroupDto.groupId)//查找点击前的ID位置
          const joinGroupDto2 = reactive<JoinGroupDto>({
            groupId: joinGroupDto.groupId,
            token: tempStore.state.token!,
            email: userInfo.id,
            ip: state.ip,
            ipCity: state.ipCity,
            classs: "tea",
          });
          if (number == -1) {
            var classs=joinGroupDto.groupId.substring(0,3).toLowerCase();
            var groupName="";
            if(classs=="fre")
            {
              openNotification("公开咨询者(" + joinGroupDto.ipCity + "):", '邀请您前来解答~', joinGroupDto.groupId, 5, "TEA");
              groupName= YiShiIdName(joinGroupDto.groupId.substring(17, 19)) + YiShiIdName(joinGroupDto.groupId.substring(19, 21)) + YiShiIdName(joinGroupDto.groupId.substring(21, 23)) + "咨询室";
            }
            else if(classs=="qus"){
              openNotification("会员(" + joinGroupDto.ipCity + "):", '邀请您前来解答~', joinGroupDto.groupId, 5, "TEA");
            }
            //console.log(joinGroupDto.groupId.substring(17, 19)+"/"+joinGroupDto.groupId.substring(19, 21)+"/"+joinGroupDto.groupId.substring(21, 23))
            roomList.value.push({
              id: joinGroupDto.groupId,
              name:groupName,
              msg: "",
              sendmsg: "",
              ip: joinGroupDto.ip,
              ipCity: joinGroupDto.ipCity,
              news: true,
              response1: [],
              response2:({
                id:"",
                boxColor:"",
                createTime:"",
                userName: "",
                HeadPortrait: "",
                sendMsg: "",
              ip: "",
              ipCity: "",
              userEmail:  "",
              quote:  "",
              }),
            });
            GetFreUserMsg(joinGroupDto2);
          }
          else {
            JoinChat(joinGroupDto2)
          }
        }, 3000);

      });
    });
    return {
      VuePictureCropper, uploaderHeadPortrait, reset, clear, getResult, selectFile, isShowDialog, result, pic, handleWindowClear, handleGoHome,
      handleCloseQuoTe,
      handleQuote,
      handleSaveGroupName,
      handleRecall,
      handleDelete,
      handleDeletePic,
      handleSendMail,
      handleSendMailOpen,
      handleSaveAI,
      handleSaveAIOpen,
      ListClassClick,
      listClass,
      articleNowMsgs,
      articleData,
      tcmsg,
      userInfo,
      store,
      handleChange,
      handleClose,
      roomList,
      inputroom,
      timeago,
      state,
      SendPublic,
      handleEdit,
      handleEditSave,
      handleCloseClass,
      handlePublish,
      isShows,
      connection,
      TimeChina,
      classs,
      // chatRecordList,
      chatQuestionsList,
      data,
      onShow,
      isShow,
      DateChinaLong,
      guestId,
      Emotions,
    };
  },
};
