
import { Emotions} from "@/store/purProcess";
import { defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import request from "@/api/http";
import router from "@/router";
import { timeago, DateChinaDate } from '@/store/formatTime';
import { TimeChina } from "@/store/guid";
import MyInput from '@/components/input/MyInput.vue';
import iComment from "@/components/Comment/CommentList.vue";
export default defineComponent({
  name: "Ads",
  components: {
    "iComment": iComment,
    "MyInput": MyInput,
  },
  setup() {
    let id = router.currentRoute.value.params.id;

    let articleInfo = ref<any>(null);
    let data = reactive({
  content: ''
});
    let handlePublish = (e: any) => data.content = e;

    function getArticleById() {
      request({
        url: "/ArticleApi/Get",
        params: {
          id: id,
        },
      }).then((res: any) => {
        articleInfo.value = res.data.response;
      });
    }

    getArticleById();

    return { articleInfo, data, timeago, DateChinaDate,Emotions,handlePublish };
  },
});
