
import { defineComponent, toRefs } from "vue";
import {
  CalendarOutlined

} from '@ant-design/icons-vue';
import { number } from "echarts/core";
export default defineComponent({
  name: "Banner",
  components: {
    CalendarOutlined
  },
  props: {
    upTime: {
      type: String,
      default: "",
    },
    keyWord: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    descriptions: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    toRefs(props);
  },
});
