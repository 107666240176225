<template>
    <Vcode :show="isShow" @success="onSuccess" @close="onClose"/>
    <a-button @click="onShow">开始验证</a-button>
</template>

<script setup>
  import { ref } from "vue";
  import Vcode from "vue3-puzzle-vcode";

  const isShow = ref(false);

  const onShow = () => {
    isShow.value = true;
  };

  const onClose = () => {
    isShow.value = false;
  };

  const onSuccess = () => {
    onClose(); // 验证成功，手动关闭模态框
  };
</script>